$bp-tablet-landscape: 1024px;

// Full width index separators
.card-body .list-items {
	margin: 0 -21px;
	overflow: auto;

	.list-items__index {
		padding: 0 21px;
	}

	.list-items__content {
		margin: 0 21px;
	}
}

// Table column sizing
.list-items__index__column,
.list-item__column {
	padding: 0 15px;

	&--systeem {
		flex: 0 0 180px;
		hyphens: auto;
		word-break: break-word;
		overflow-wrap: break-word;

		@media screen and (max-width: 1280px) {
			flex-basis: 140px;
		}

		@media screen and (max-width: 1200px) {
			flex-basis: 120px;
		}
	}

	&--name {
		flex: 1 1 60%;
		word-break: break-word;
		overflow-wrap: break-word;
	}

	&--auditlog-session {
		width: 60px;
		flex-basis: 60px;
		flex-grow: 1;
	}

	&--auditlog-type {
		width: 100px;
		flex-basis: 100px;
		flex-grow: 1;
	}

	&--auditlog-text {
		width: 60%;
		flex-basis: 60%;
		flex-grow: 1;
	}

	&--auditlog-name {
		width: 10%;
		flex-basis: 10%;
		flex-grow: 1;
	}

	&--auditlog-time {
		width: 120px;
		flex-basis: 120px;
		flex-grow: 1;
	}

	&--client-name {
		width: 25%;
		flex-basis: 25%;
		flex-grow: 1;
	}

	&--client-contract-administrator {
		width: 15%;
		flex-basis: 15%;
		flex-grow: 1;
	}

	&--client-objects {
		width: 60%;
		flex-basis: 60%;
		flex-grow: 1;
	}

	&--customer-name {
		width: 15%;
		flex-basis: 15%;
		flex-grow: 1;
	}

	&--customer-objects {
		width: 40%;
		flex-basis: 40%;
		flex-grow: 1;
	}

	&--customer-role {
		width: 10%;
		flex-basis: 10%;
		flex-grow: 1;
	}

	&--customer-company-name {
		width: 15%;
		flex-basis: 15%;
		flex-grow: 1;
	}

	&--customer-email-address {
		width: 20%;
		flex-basis: 20%;
		flex-grow: 1;
	}

	&--customer-phone-number {
		width: 10%;
		flex-basis: 10%;
		flex-grow: 1;
	}

	&--customer-buttons {
		flex-basis: 200px;
		flex-shrink: 0;
		align-content: flex-end;
	}

	&--employee-name {
		width: 60%;
		flex-basis: 60%;
		flex-grow: 1;
	}

	&--employee-hsp-username {
		width: 35%;
		flex-basis: 35%;
		flex-grow: 1;
	}

	&--employee-working-role {
		width: 30%;
		flex-basis: 30%;
		flex-grow: 1;
	}

	&--employee-email-address {
		width: 40%;
		flex-basis: 40%;
		flex-grow: 1;
	}

	&--employee-buttons {
		flex-basis: 200px;
		flex-shrink: 0;
		align-content: flex-end;
	}

	&--postal {
		flex: 0 0 90px;
		padding-right: 0;
	}

	&--city {
		flex: 0 0 180px;

		hyphens: auto;
		word-break: break-word;
		overflow-wrap: break-word;

		@media screen and (max-width: 1280px) {
			flex-basis: 140px;
		}

		@media screen and (max-width: 1200px) {
			flex-basis: 120px;
		}
	}

	&--project-code {
		flex: 0 0 170px;
		word-break: break-word;
		overflow-wrap: break-word;
		padding-right: 0;

		@media screen and (max-width: 1200px) {
			flex-basis: 140px;
		}
	}

	&--customer-company {
		padding-right: 5px !important;
		flex: 0 0 140px;
		word-break: break-word;
		overflow-wrap: break-word;
	}

	&--element-name {
		flex: 2 2 60%;
		padding-right: 0;

		hyphens: auto;
		word-break: break-word;
		overflow-wrap: break-word;
	}

	&--element-registration-nr {
		flex: 0 0 140px;
		padding-right: 0;
		word-break: break-word;
		overflow-wrap: break-word;

		@media screen and (max-width: 1180px) {
			flex-basis: 110px;
		}
	}

	&--element-code {
		flex: 0 0 140px;
		padding-right: 0;

		word-break: break-word;
		overflow-wrap: break-word;

		@media screen and (max-width: 1180px) {
			flex-basis: 100px;
		}
	}

	&--element-ruimte {
		flex: 0 0 160px;
		padding-right: 0;

		@media screen and (max-width: 1180px) {
			flex-basis: 120px;
		}
	}

	&--element-verdieping {
		flex: 0 0 120px;
		padding-right: 0;

		@media screen and (max-width: 1180px) {
			flex-basis: 110px;
		}
	}

	&--element-ruimte,
	&--element-verdieping {
		hyphens: auto;
		word-break: break-word;
		overflow-wrap: break-word;
	}

	&--bulk-dropdown {
		flex: 0 0 95px;
		padding-left: 5px;
	}

	&--element-progress {
		flex: 0 0 94px;
		padding-right: 0;
	}

	&--status {
		flex: 0 0 66px;
		padding-right: 0;
	}

	&--element-buttons {
		flex-basis: 150px;
		flex-shrink: 0;
	}

	&--bulk-actions {
		flex-basis: 180px;
		flex-grow: 1;
	}
}

// Table styling
.list-items {
	margin: 0 -21px;

	a {
		color: #505050;

		&:hover {
			text-decoration: none;
		}
	}

	// Index row
	&__index {
		width: 100%;
		display: flex;
		margin: 0 0 1em 0;
		padding: 0 21px;
		color: #8b8b8b;
		min-height: 45px;
		border-bottom: 1px solid #d8d8d8;
		align-items: stretch;
		justify-content: stretch;

		&:empty {
			min-height: 10px;
		}

		@media all and (max-width: $bp-tablet-landscape) {
			display: none;
		}
	}

	&__content {
		padding: 0;
	}
}

.list-item__tablet {
	display: none;
	width: 100%;

	@media all and (max-width: $bp-tablet-landscape) {
		display: flex;
	}
}

.list-item__desktop {
	display: flex;
	align-items: center;
	width: 100%;

	@media all and (max-width: $bp-tablet-landscape) {
		display: none;
	}
}

.list-item {
	width: 100%;
	display: flex;
	min-height: 50px;
	border: 1px solid #d8d8d8;
	border-radius: 3px;
	margin-top: 20px;
	flex-direction: row;
	justify-content: stretch;
	align-items: center;

	&.list-cell,
	.list-cell {
		padding: 15px;
		//flex-grow: 1;
		list-style: none;
		box-sizing: border-box;

		&__systeem {
			display: flex;
			flex: 0 0 100%;

			.list-cell {

				&__left,
				&__right {
					flex: 1 1 50%;
				}

				&__right {
					text-align: right;
				}
			}
		}

		&__elementen {
			position: relative;

			.list-cell {

				&__left,
				&__right {
					flex: 1 1 50%;
				}
			}
		}

		.list-cell__heading {
			display: none;
			font-weight: bold;

			@media all and (max-width: $bp-tablet-landscape) {
				display: inline-block;
				flex: 1;
				max-width: 150px;
				min-width: 150px;
			}
		}

		&--bulk-dropdown {
			margin-top: 0;

			.icon-plus,
			.icon-trash,
			.icon-edit {
				margin: -2px 4px 0 0;
			}
		}
	}

	.list-row {
		display: flex;
		width: 100%;

		&--element-buttons {
			padding-top: 20px;
		}

		&--bulk-dropdown {
			margin-top: 10px;

			.icon-plus {
				margin: -2px 4px 0 0;
			}
		}
	}

	&__column {

		&--status {
			justify-content: flex-end;
			text-align: right;

			padding: 11px 15px 11px 0;
			white-space: nowrap;

			i,
			svg {
				vertical-align: middle;
				width: 30px;
				height: 30px;
				line-height: 22px;
				border-width: 1px;
				border-style: solid;
				border-color: $color-text-light;
				border-radius: 50%;
			}

			.icon-checked {
				color: $green;
				border-color: $green;
			}

			.icon-warning {
				color: $red;
				border-color: $red;
			}

			.icon-recovered {
				color: $blue;
				border-color: $blue;
			}
		}
	}

	&--inactive {
		opacity: .7;
	}

	&__content {
		position: relative;

		&--name,
		&--systeem {
			font-weight: 700;
		}

		&--dossier {
			padding-top: 10px;
		}

		&--element {
			padding-bottom: 10px;
			padding-right: 20px;
		}

		&--status {
			position: absolute;
			top: 10px;
			right: 10px;
			z-index: 200;

			i,
			svg {
				vertical-align: middle;
				width: 30px;
				height: 30px;
				line-height: 22px;
				border-width: 1px;
				border-style: solid;
				border-color: $color-text-light;
				border-radius: 50%;
			}

			.icon-checked {
				color: $green;
				border-color: $green;
			}

			.icon-warning {
				color: $red;
				border-color: $red;
			}

			.icon-recovered {
				color: $blue;
				border-color: $blue;
			}
		}
	}
}

.list-items__content-accordion {
	.list-items__content-group {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
		margin: 19px -20px 0;
		padding: 0 20px;
		background: $color-bg-body;
		text-transform: uppercase;
		color: $color-grey-dark;

		.content-group__title {
			flex: 1 0 auto;
		}

		.content-group__toggle {
			flex: 0 0 auto;

			button {
				svg {
					transform: rotate(0);
					transition: transform .2s ease;
				}
			}
		}

		&.not-toggleable {
			padding: 5px 26px;
		}
	}

	&.accordion-item-open {
		.list-items__content-group-body {
			display: block;
			animation-duration: .25s;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;
			opacity: 1;
		}

		.content-group__toggle {
			button {
				svg {
					transform: rotate(90deg);
				}
			}
		}
	}

	.list-items__content-group-body {
		display: none;
	}
}

// Custom lists
.list-items--logboek {
	.list-items__content.list-items__content {
		margin-left: 0;
		margin-right: 0;

		& > .list-item {
			border-radius: 0;
		}

		& > p:last-child {
			margin: 21px 21px 0;
		}
	}

	.list-item {
		border-radius: 3px;
		min-height: 50px;

		&:not(:first-child) {
			margin-top: -1px;
		}

		&__column {
			padding-top: 12px;
			padding-bottom: 12px;

			&--action-icon {
				.arrow-right-icon {
					top: 50%;
					transform: translate3d(0, -50%, 0);
				}
			}
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

