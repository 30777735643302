// Select 2
.select2-container {

	.select2-selection--single {
		border: 1px solid darken($light, 1%);
		height: 38px;

		.select2-selection__rendered {
			line-height: 36px;
			padding-left: 12px;
		}

		.select2-selection__arrow {
			height: 34px;
			width: 34px;
			right: 3px;

			b {
				border-color: darken($light, 15%) transparent transparent transparent;
				border-width: 6px 6px 0 6px;
			}
		}
	}
}

.select2-container--open {
	.select2-selection--single {

		.select2-selection__arrow {

			b {
				border-color: transparent transparent darken($light, 15%) transparent !important;
				border-width: 0 6px 6px 6px !important;
			}
		}
	}
}

.select2-results__option {
	padding: 6px 12px;

	&[role="group"] {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.select2-dropdown {
	border: 1px solid darken($light, 5%);
	box-shadow: $shadow;
}

.select2-container--default {
	.select2-search--dropdown {
		padding: 10px;
		background-color: lighten($light, 5%);

		.select2-search__field {
			border: 1px solid darken($light, 5%);
		}
	}

	.select2-results__option--highlighted[aria-selected] {
		background-color: $custom;
	}

	.select2-results__option[aria-selected=true] {
		background-color: $light;
		color: $dark;

		&:hover {
			background-color: $custom;
			color: $white;
		}
	}
}

.select2-container .select2-selection--multiple {
	min-height: 38px;
	border: 1px solid darken($light, 5%) !important;

	.select2-selection__rendered {
		padding: 2px 10px;
	}

	.select2-search__field {
		margin-top: 7px;
		border: 0;
	}

	.select2-selection__choice {
		background-color: $custom;
		border: 1px solid transparent;
		color: $white;
		border-radius: 3px;
		padding: 0 7px;
	}

	.select2-selection__choice__remove {
		color: $white;
		margin-right: 5px;

		&:hover {
			color: $white;
		}
	}

}

.select2-results__options {
	-webkit-overflow-scrolling: touch;
}

// Custom Eleven
.select2-results__option--highlighted[aria-selected] {
	background-color: $color-grey-dark !important;
}

.select2-results__option[aria-selected=true] {
	background-color: lighten($color-grey, 10%) !important;
	color: $color-text !important;

	&:hover {
		background-color: $color-grey-dark !important;
		color: $white !important;
	}
}

.select2-container--default .select2-search--dropdown {
	background-color: white;
}

.select2-selection--single {
	border: 1px solid #ced4da !important;
}
