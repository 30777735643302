// Base Modal styles
.modal {
	// Fixes issues with CKEditor in modal (link plugin)
	// @link https://github.com/ckeditor/ckeditor5/issues/650#issuecomment-342415155
	z-index: $z-index-modal !important;

	overscroll-behavior: contain;

	button.close > span {
		font-size: 40px;
	}

	.modal-dialog {
		max-width: 90vw;
	}

	.modal-content {
		padding: 15px 70px 25px 70px;
		border-radius: 4px;
		border: none;
	}

	.modal-header {
		border-bottom: none;

		.modal-title {
			font-size: 18px;
			color: $color-text;
			margin-top: 6px;
		}
	}

	.modal-body {
		padding-top: 2px;

		@media screen and (max-width: 768px) {
			.form-group input {
				margin-bottom: 5px;
			}
		}
	}

	&.modal-no-padding .modal-content {
		padding: 0
	}

	// Set min width for buttons
	.btn {
		@extend .btn--min-width;
	}
}

.modal-backdrop.show {
	opacity: .3;
}

// Bootbox
.modal.bootbox {
	.modal-dialog {
		width: 600px;
	}

	.bootbox-close-button {
		font-size: 40px;
	}

	.modal-header {
		display: block;
	}

	.modal-body:first-child {
		padding-top: 30px;
	}

	.modal-footer {
		padding-top: .7em;
		border-top: none;
		flex-direction: row-reverse;

		button {
			@extend .btn--min-width-small;

			&.btn-link {
				margin-left: 5px;
			}
		}
	}
}

/// Image-only modal dynamic width
.image-modal {
	text-align: center;

	.modal-dialog {
		width: auto;
		display: inline-block;
	}
}

/// Prevent content scrolling while in modal
.modal-open .content-page {
	overflow: hidden;
}

.modal-backdrop {
	text-align: center;
	color: #ffffff;
	height: 100vh;
	display: grid;
	z-index: $z-index-modal-backdrop !important;

	.loading-spinner {
		margin: auto;
	}
}

.modal-blockingoverlay {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, .1);
	z-index: 1000;
	animation: fadeInModalBlockingOverlay 1s ease-in-out;
	pointer-events: auto;
	visibility: visible;
	cursor: wait;
	opacity: 1;
	text-align: center;
	color: #ffffff;
}

@keyframes fadeInModalBlockingOverlay {
	0% {
		opacity: 0;
	}

	40% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
