details.accordion-details {
	border-top: 1px solid #cccccc;
	margin: 0 0 .5em;

	summary {
		text-transform: uppercase;
		padding: .5em 0;
		font-size: 14px;
		color: #505050;
		list-style: none;

		// Hide the native arrow of chrome / safari
		&::-webkit-details-marker {
			display: none;
		}

		// Hide the native arrow in firefox
		&::marker {
			content: '';
			display: none;
		}
	}

	&[open] > summary {
		margin-bottom: 1em;
	}

	.summary-arrow {
		float: right;
		position: relative;
		top: 4px;
		fill: currentColor;
		transition: transform .2s ease;
	}

	&[open] > summary .summary-arrow {
		transform: rotate(90deg);
	}
}
