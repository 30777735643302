table {
	.no-padding-left {
		padding-left: 0;
	}

	td {
		vertical-align: middle;

		.attachment {
			display: inline-flex;
			align-items: center;
			margin: 2px 0;
		}
	}
}

.user-overview td {
	// Overschrijven default table styling
	vertical-align: middle;

	svg {
		vertical-align: middle;
	}

	&.tool-inspection-overdue {
		color: #dc3545;
	}

	&.tool-inspection {
		color: #f0ad4e;
	}

	&.tools-comment {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 1px;
	}
}

.table-container {
	overflow: auto;
	max-width: 100%;
	clear: both;
}

.tenant-details-table {
	td {
		word-break: break-word;
		overflow-wrap: break-word;
	}

	&__col-th {
		width: 180px
	}

	&__col-td {
		width: 400px;
		max-width: 50vw;
	}
}

.wysiwyg-editor-output {
	overflow: auto;

	table {
		width: auto;
		max-width: 100%;
		border-collapse: collapse;
		border-spacing: 0;

		th, td {
			border: 1px solid $color-grey;
			padding: 5px;
		}

		th {
			vertical-align: bottom;
		}

		td {
			vertical-align: middle;
		}
	}
}
