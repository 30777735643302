.list-accordion-items {
	max-width: 100%;
}

.accordion-item {
	display: block;
	background: #ffffff;
	border-bottom: 1px solid $color-grey;

	&__remarks {
		display: block;
		margin-top: 10px;
	}

	// HEADING
	&__heading {
		position: relative;
		display: block;
		max-width: 100%;

		button {
			background: none;
			border: none;
			width: 100%;
			height: 100%;
			display: block;
			text-align: left;
			padding: 10px 20px 10px 0;
			min-height: 62/14*1em;

			// Overwrite reset
			&:hover {
				cursor: pointer;
			}
		}

		&__export-checkbox {
			float: left;
			display: inline-block;
			width: 40px;
			padding: 20px 0;
			text-align: center;
			margin: 0 0 0 -20px;

			& + button {
				width: calc(100% - 40px);
			}
		}

		&__title, &__subtitle {
			display: block;
		}

		&__subtitle {
			color: $color-text-light;
		}

		&__title {
			color: $color-text;

			&__separator {
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		&__title__title-text span {
			display: inline-block;
		}

		&__deadline {
			position: relative;
			color: #8b8b8b;
			padding: 10px 0 0;

			@media screen and (min-width: 1024px) {
				position: absolute;
				right: 50px;
				top: 20px;
				color: #8b8b8b;
				padding: 0;
			}
		}

		&__image {
			position: relative;
			padding: 10px 0 0;
		}

		.arrow-right-icon {
			position: absolute;
			right: 0;
			top: 20px;
			transition: transform .15s ease-in-out;
			color: $color-text-light;
		}
	}

	// BODY
	&__body {
		max-width: 100%;

		.accordion-item__body__wrapper {
			padding-bottom: 15px;
			display: none;
		}
	}
}

// OPEN STATE
.accordion-item-open {
	> .accordion-item__heading {
		.arrow-right-icon {
			transform: rotate(90deg);
		}
	}

	> .accordion-item__body {
		> .accordion-item__body__wrapper {
			display: block;
		}
	}

	// Styling to make sure that select2 dropdown are aligned to the right edge
	// in accordion items that are open. This is needed since select2 does not
	// provide an options to align right.
	.select2-container {
		left: auto !important;
		right: 0 !important;

		.select2-dropdown {
			position: relative !important;
			margin-right: 50px !important;
			max-width: 550px;
		}
	}
}

.accordion-item__body__wrapper {
	& > *:last-child {
		margin-bottom: 0;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

// For logboek headings outside of the accordion
.logboek-heading {
	display: block;

	&__subtitle {
		display: block;
		color: $color-text-light;
	}

	&__title {
		color: $color-text;
	}

	&__separator {
		padding-left: 5px;
		padding-right: 5px;
	}
}

// VARIANTEN ACCORDION ITEMS
// Kleine weergave logboek
.list-accordion-items--logboek {
	.accordion-item {
		margin-left: -21px;
		margin-right: -21px;
		padding-left: 20px;
		padding-right: 20px;
		border: 1px solid $color-grey;
		border-radius: 3px;
		min-height: 50px;

		& + .accordion-item {
			margin-top: -1px;
		}
	}

	& > .accordion-item {
		border-radius: 0;
	}

	.remark-overview .remark-item:last-child {
		border-bottom: none;
		margin-bottom: 0;
		padding-bottom: 5px;
	}
}

.accordion-item-row {
	padding-top: 10px;
	padding-bottom: 10px;
	border-top: 1px solid $color-grey;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	p, ol, ul {
		&:last-child {
			margin-bottom: 0;
		}
	}

	&:last-child {
		padding-bottom: 0;
	}

	@media screen and (max-width: 650px) {
		flex-wrap: wrap;
	}
}

// Logboek onderhoudsregels
.instruction-item__row {
	@extend .accordion-item-row;
	flex-wrap: wrap;

	&:last-child {
		padding-bottom: 10px;
	}
}

.instruction-item {
	&__row {
		display: flex;
		flex-direction: row;
		justify-content: stretch;
		flex-wrap: nowrap;
		max-width: 100%;
	}

	&__title {
		flex: 1 1 auto;
		padding-right: 15px;
	}

	&__subtitle {
		flex: 1 1 auto;
		text-align: right;
		margin-left: auto;
		max-width: 40%;
		font-weight: $font-weight-normal;
	}

	&__value {
		flex: 1 1 auto;
		text-align: right;
		margin-left: auto;
		max-width: 40%;
	}

	.answerline__heading {
		font-weight: bold;
		margin-top: 10px;
	}

	@media screen and (max-width: 600px) {
		&__row {
			flex-direction: column;
		}
		&__value {
			text-align: left;
			margin-left: 0;
		}
	}
}

.instruction-item-comment {
	margin-top: 8px;
	width: 100%;

	&__heading {
		display: block;
		font-style: italic;
	}
}

// Nested Item
.accordion-item.accordion-item--nested {
	margin-left: 0;
	margin-right: 0;
}

// Logboek Instructions
.accordion-item.accordion-item--instruction {
	min-height: 28px;

	.accordion-item__heading button {
		min-height: 50/14*1em;
	}

	.arrow-right-icon {
		top: 15px;
		height: 21px;
		width: 10px;
	}

	&:not(:first-child) {
		margin-top: 5px;
	}
}

.accordion-item.accordion-item--grey {
	background-color: #f3f6f8;
}
