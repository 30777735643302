.element-detail-header {
	@media screen and (min-width: 480px) {
		display: grid;
		grid-template-areas: "title edit" "info image" "extra-info extra-info";
		grid-template-columns: 1fr 120px;
		grid-gap: 0 1rem;
	}

	@media screen and (min-width: 600px) {
		grid-template-columns: 1fr 200px;
		grid-template-areas: "title edit" "info image" "extra-info image";
	}

	&__title {
		grid-area: title;
	}

	&__edit {
		grid-area: edit;
	}

	&__info {
		grid-area: info;
	}

	&__image {
		grid-area: image;
	}

	&__extra-info {
		grid-area: extra-info;
	}
}
