.icon-add-comment {
	transform: translate3d(0, 0, 0);
}

.arrow-right-icon,
.icon-right-arrow {
	color: $color-text-light;
}

svg:not(:root) {
	fill: currentColor;
}

.dropdown-item.dropdown-item--with-icon {
	padding-left: 2.6em;
	position: relative;

	.dropdown-item__icon {
		position: absolute;
		left: 1em;
		top: 6px;
	}
}