.general-tooltip {
	.arrow {
		&:before {
			border-top-color: $color-grey-dark;
		}
	}

	.tooltip-inner {
		background-color: $color-grey-dark;
		color: #ffffff;
		max-width: 450px;
	}

	&.bs-tooltip-bottom {
		.arrow {
			&:before {
				border-bottom-color: $color-grey-dark;
			}
		}
	}
}
