@import "../settings";

.blockingoverlay,
.menublockingoverlay {
	display: block;
	visibility: hidden;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(#000, .1);
	pointer-events: none;
	transition: opacity .5s .3s ease-in-out;
	z-index: -1;
}

html[data-loading=true] .blockingoverlay {
	pointer-events: auto;
	z-index: $z-index-blockingoverlay;
	visibility: visible;
	cursor: wait;
	bottom: 0;
	right: 0;
	opacity: 1;
}

@media #{$mq-small-only} {
	.mobile-menu-open {
		.content-page {
			.menublockingoverlay {
				pointer-events: auto;
				z-index: 4;
				visibility: visible;
				bottom: 0;
				right: 0;
				opacity: 1;
			}
		}
	}
}


