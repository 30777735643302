/// Base instruction rule
.instruction-rule {
	padding: 0;
	border-top: 1px solid #d9e3e9;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-content: stretch;
	align-items: flex-start;

	@media screen and (min-width: 1024px) {
		flex-wrap: nowrap;
	}

	&__title {
		font-size: $font-size-base;
		line-height: 20/14*1em;
		font-weight: $font-weight-light;

		padding: 0;
		margin: 0;

		order: 0;
		flex: 0 1 auto;
		align-self: auto;

		width: 50%;

		.btn-link,
		button {
			display: inline-block;
			max-width: 100%;

			padding: 14px 1em 7px 0;
			margin: 0;
			border: none;
			background: none;
			text-align: inherit;
			font: inherit;
			color: inherit;

			cursor: pointer;
			white-space: normal;

			svg {
				color: inherit;
				display: inline-block;
				vertical-align: top;
				width: 18px;
				margin-left: 2px;
			}
		}

		.info-tooltip {
			display: inline-block;
			vertical-align: -14px;
			position: relative;
			top: -4px;
		}
	}

	&__subtitle {
		font-size: $font-size-base;
		line-height: 20/14*1em;
		font-weight: $font-weight-normal;
		text-align: right;
		margin: 20px 0 0;
	}

	&__group-toggle {
		order: 0;
		flex: 0 1 auto;
		align-self: auto;
		height: 50px;
		width: 50px;
		text-align: right;

		margin-left: auto;

		background: none;
		border: none;
		cursor: pointer;
	}

	&__select {
		order: 0;
		flex: 0 0 auto;
		align-self: auto;
		margin: 7px 0 7px auto;

		.select2-selection__rendered {
			text-align: center;
		}

		.vue-multiselect-wrapper {
			width: 100%;
			flex: 0 0 auto;

			.multiselect__placeholder {
				white-space: nowrap;
			}
		}
	}

	&__checkbox {
		order: 0;
		flex: 0 0 auto;
		align-self: auto;
		padding: 0 0 8px;
		margin: 0;
	}

	&__text-input {
		flex: 0 0 auto;
		margin: 0;
		padding: 0;

		&:last-child {
			padding-bottom: 8px;
		}
	}

	&__number-input {
		flex: 0 0 auto;
		margin: 0;
		padding: 0 0 8px;
	}

	&__file-input {
		flex: 0 0 auto;
		margin: 0;
		padding: 0 0 8px;

		.custom-file {
			.btn-file {
				margin-right: 0;
				display: flex;
				align-items: center;
				padding: .375rem .75rem;
				font-size: 1rem;
				font-weight: 400;
				line-height: 1.5;
				color: #495057;
				text-align: center;
				background-color: #e9ecef;
				border: 1px solid #ced4da;

				&:hover {
					background-color: #6c757d;
					color: $white;
				}
			}

			input[type="file"] {
				display: none;
			}
		}

		.file-action-label {
			margin-right: 0;
		}
	}

	&__readonly {
		.input-group {
			.form-control-plaintext {
				position: relative;
				flex: 1 1 auto;
				width: 1%;
				margin-bottom: 0;
				padding: .375rem .75rem;

				&.is-invalid {
					color: #dc3545;
				}
			}
		}
	}

	&__textarea {
		flex: 0 0 auto;
		width: 100%;
		margin: 3px 0 1em auto;
	}

	&__checkbox,
	&__text-input,
	&__number-input,
	&__file-input,
	&__select,
	&__textarea,
	&__readonly {
		text-align: right;
		overflow: visible;
		position: relative;

		select.form-control,
		.input-group {
			width: 12em;
			max-width: 12em;
		}

		.form-inline {
			padding: 8px 0;
		}

		.form-group {
			flex: 0 1 auto;
			max-width: 100%;
			flex-wrap: nowrap;
		}
	}

	&__select {
		// Overflow needs te be visible for the dropdown
		overflow: visible;

		.input-group--select-nenCode {
			width: 20em;
			max-width: 20em;

			.vue-multiselect-wrapper .multiselect__option {
				white-space: normal !important;
			}
		}

	}

	&__text-input {
		grid-area: input;
		width: auto;
		max-width: none;
		margin-left: auto;

		.form-group {
			margin-left: auto;
		}

		&--range {
			.form-group {
				margin-left: 0;
			}

			.input-group {
				width: 9em;
			}
		}
	}

	&__input__saved-icon {
		position: absolute;
		left: calc(100% + 6px);
		top: 12px;
		color: $green;
		opacity: 0;
		animation: savedAnimation 4s cubic-bezier(0.455, 0.03, 0.515, 0.955);

		svg {
			fill: $green;
		}

		.instruction-rule[data-nvt="true"] & {
			display: none;
		}
	}

	&__saved-status--saving {
		margin: 70px 0 8px auto;
		width: 20px;
		height: 20px;
		background: url("/images/spinner.svg");
		animation: fadeIn 1s cubic-bezier(1, 0, 0, 1);

		.brigit-design & {
			background-image: url("/src/static/images/spinner.svg")
		}

		.instruction-rule[data-nvt="true"] & {
			margin-top: 18px;
		}
	}

	&__saved-status--success {
		margin: 70px 0 8px auto;
		opacity: 0;
		animation: savedAnimation 4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
		overflow: hidden;
		color: $green;
		display: none;

		svg {
			fill: $green;
		}

		.instruction-rule[data-nvt] & {
			margin-top: 18px;
			display: block;
		}
	}

	@keyframes savedAnimation {
		0% {
			opacity: 0;
			transform: scale(0.5);
		}
		5% {
			opacity: 1;
			transform: scale(1);
		}
		95% {
			opacity: 1;
			transform: scale(1);
		}
		100% {
			opacity: 0;
			transform: scale(0.5);
		}
	}

	&__comment {
		border-left: 4px solid $light3;
		padding-left: 10px;
		margin-bottom: 14px;

		a {
			display: inline-block;
			margin-right: 1em;
		}
	}

	&__group-title {
		color: #646464;
	}

	.btn-group .btn {
		min-width: 58/14*1em;
		white-space: nowrap;
	}
}

.instruction-rule {

	display: grid;
	width: 100%;
	max-width: 100%;
	overflow: visible;

	grid-template-areas: "title title nvt save" "addcomment input input save" "comment comment comment filler";
	grid-template-columns: 180px 1fr 100px 30px;

	@media screen and (max-width: 700px) {
		grid-template-columns: 110px 1fr 100px 30px;
	}

	@media screen and (max-width: 599px) {
		grid-template-areas: "title nvt save" "input input save" "addcomment addcomment filler" "comment comment filler";
		grid-template-columns: 1fr 100px 30px;
	}

	& > div:first-child {
		grid-area: title;
	}

	&__title {
		grid-area: title;
		width: auto;
		max-width: none;
	}

	&__input,
	&__checkbox,
	&__number-input,
	&__textarea {
		grid-area: input;
		width: auto;
		max-width: 100%;
		margin-left: auto;
		align-self: self-end;

		.form-group {
			margin-left: auto;
		}

	}

	&__saved-status,
	&__spinner {
		grid-area: save;
		flex-grow: 0;
	}

	&__check {
		grid-area: save;
		flex-grow: 0;
	}

	&__nvt-button {
		grid-area: nvt;
		margin: auto 0 8px auto;
		padding-top: 14px;
		white-space: nowrap;
	}

	&__add-comment {
		grid-area: addcomment;
		margin-bottom: 7px;
		padding-top: 8px;

	}

	&__comment {
		grid-area: comment;

	}

	// overwrite for bootstraps default styling for small screens
	@media screen and (max-width: 575px) {
		.form-inline .form-group {
			display: flex;
			flex: 0 0 auto;
			flex-flow: row wrap;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 0;
		}
	}
}

/// Instruction group title
.instruction-group-title {
	display: flex;
	padding: 0;

	&__total,
	h5 {
		line-height: 20/14*1em;
		text-transform: uppercase;
		font-weight: $font-weight-normal;
		color: #646464;
	}

	h5 {
		margin: 0;
		padding: 14px 1em 14px 0;
	}

	&__total {
		margin-left: auto;
		margin-right: 1em;
		align-self: center;
		font-size: 15px;
	}

	& + .arrow-right-icon {
		top: 24px;
	}
}
