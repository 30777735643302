.form-group {
	margin-bottom: 2rem;

	.label,
	label {
		color: $color-text-light;
	}
}

.form-inline {
	position: relative;

	.label,
	label {
		margin-right: 15px;
	}
}

.form-control {
	padding: (21px / 38px * 1rem) .75rem;
}

.label {
	// Extend styling of 'label' which are defined by bootstrap
	@extend label;
}

label.error {
	&,
	.form-group &,
	.form-inline & {
		display: none;
		width: 100%;
		margin: .25rem 0 0;
		color: #dc3545;
	}
}

.checkbox-single, .checkbox-single label {
	display: block;
}

.input-date {
	display: inline-block;
	width: 146/14*1em;
	min-height: 38/14*1em;
	text-align: left;
	vertical-align: middle;
	padding-right: 0;
	padding-top: 8/14*1em;

	&::placeholder {
		color: $color-text-light;
	}
}

.select-checkbox {
	select {
		border: none;
		opacity: 0;
		position: absolute;
		top: -20px;
		left: -20px;
		width: calc(100% + 40px);
		height: calc(100% + 40px);
		backface-visibility: hidden;
		z-index: 10;

		&:hover {
			cursor: pointer;
		}
	}

	.select-checkbox-status {
		height: 100%;
		width: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
		user-select: none;

		&--done {
			background-color: green;
		}

		&--warning {
			background-color: yellow;
		}

		&--error {
			background-color: red;
		}
	}
}

.ck-editor__editable {
	min-height: 150px;
	max-height: 400px;
	padding-bottom: 40px;
}

// CKEditor has some different styles for gespreksverslagen
#gespreksverslagen {
	.ck-editor {
		.ck-editor__top {
			position: initial;
		}
	}

	.ck-editor__editable {
		min-height: 500px;
		max-height: initial;
	}
}

// Fix toolbar not showing up on iPad
.ck-editor {
	z-index: 1;

	.ck-editor__top {
		z-index: 2;

		// Position ckeditor toolbar at bottom
		position: absolute;
		bottom: 1px;
		left: 1px;
		right: 1px;

		.ck-toolbar {
			border-left: none;
			border-right: none;
			border-bottom: none;
			border-radius: 0 !important;
		}
	}
}

.js-select-tags-container {
	display: block;
	position: relative;
}

.js-select-tags-container > div {
	list-style: none;
	padding: 0;
	padding-top: 5px;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.select2-selection--multiple {
	.select2-selection__choice {
		display: none !important;
	}
}

.select2-results__option {
	padding: 12px 15px !important;

	&:not(:first-child) {
		border-top: 1px solid lighten($color-grey, 5%);
	}
}

// Style selected options in select2 list
.select2-results__option[aria-selected='true'] {
	&::before {
		content: '✓';
		padding-right: 7px;
	}
}

input[readonly][aria-readonly="false"] {
	background: #ffffff;
}

label.input-group-text {
	margin: 0;
	color: inherit;
	font-weight: inherit;
}

.input-group .tooltip {
	bottom: 100%;
	max-width: 100%;

	.arrow {
		left: 50%;
		margin-left: -.4rem;
	}
}

.tooltip {
	z-index: 800;

	&--red {
		.tooltip-inner {
			background: $red;
		}

		.arrow {
			&:before {
				border-top-color: $red;
			}
		}
	}

	&--warning {
		.tooltip-inner {
			background: $warning;
			color: #000000;
		}

		.arrow {
			&:before {
				border-top-color: $warning;
			}
		}
	}

	&--unit {
		.arrow {
			right: 0;
			left: auto !important;
			margin-right: 1.5rem;
		}
	}
}

.custom-file {
	.btn-file {
		margin-right: 0;
		display: inherit;
		align-items: center;
		padding: .375rem .75rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		text-align: center;
		background-color: #e9ecef;
		border: 1px solid #ced4da;

		&:hover {
			background-color: #6c757d;
			color: $white;
		}
	}

	input[type="file"] {
		display: none;
	}
}

.form--compact {
	.form-group {
		/*margin-bottom: 2rem;*/
		margin-bottom: 12px;

		& + .row:not(.form-group):last-child {
			margin-top: 2em;
		}
	}

	label {
		margin-bottom: 2px;
	}
}

.form-control.is-invalid, .was-validated .form-control:invalid {
	&.warning {
		border-color: #ffc107;
		background-image: none;
	}
}
