.nen2767-matrix-wrapper {
	overflow: auto;

	&.nen2767-matrix-wrapper--logbook {
		margin: 0 -7px;
		@media screen and (max-width: 900px) {
			margin: 0 -15px;
		}
	}
}

table.nen2767-matrix {
	min-width: 100%;

	thead th {
		vertical-align: bottom;
	}

	tr {
		.nen2767-matrix-wrapper--logbook & {
			border-bottom: 1px solid $light3;
			transition: background .05s ease-in-out;
		}
	}

	tbody tr:hover {
		background: $light3;
	}

	th,
	td {
		text-align: center;

		&:first-child {
			text-align: right;
			width: 50%;
			padding-right: 15px;

			.nen2767-matrix-wrapper--logbook & {
				line-height: 1.1em;
				padding-top: .2em;
				padding-bottom: .2em;

				@media screen and (min-width: 1280px) {
					// text-indent prevents the label from breaking to the next line by just a few pixels
					text-indent: -6px;
				}
			}
		}

		.nen2767-matrix-wrapper.nen2767-matrix-wrapper--pdf & {
			width: auto;
		}
	}

	td.red,
	td.yellow,
	td.green {
		color: $color-text-light;

		&.selected {
			color: #000000;
		}
	}

	td.selected {
		&.red {
			background: $danger;
		}

		&.yellow {
			background: $warning;
		}

		&.green {
			background: $success;
		}
	}
}

.rule-group__content__answer__line.rule-group__content__answer__line--nen2767 {
	display: block;

	.rule-group__content__answer__line__label--nen2767 {
		display: none;
	}

	.rule-group__content__answer__line__value {
		min-width: 100%;
		max-width: 100%;
	}
}
