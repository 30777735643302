body {
	color: $color-text;
}

h4, h4.card-title {
	color: $color-text;
}

h4, h5 {
	font-weight: normal;
}

h5, .light-heading {
	color: $color-text-light;
}

.custom-modal h5 {
	font-size: 14px;
}

label {
	font-family: $font-primary;
}

.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6 {
	font-family: $font-secondary;
	margin: 10px 0;
	text-rendering: optimizeLegibility;
}

h1 {
	line-height: calc(43/36*1em);
	font-size: 36px;
}

h2 {
	line-height: calc(35/30*1em);
	font-size: 30px;
}

h3 {
	line-height: calc(30/24*1em);
	font-size: 24px;
}

h4 {
	line-height: calc(22/18*1em);
	font-size: 18px;
}

h5 {
	font-size: 15px;
}

h6 {
	font-size: 12px;
}

p {
	line-height: 1.6;
}

ol, ul {
	padding-left: 15px;
}
