.checkbox {
	label {
		display: inline-block;
		padding: 4px 0 4px 15px;
		position: relative;
		font-weight: normal;

		&::before {
			background-color: $white;
			border-radius: 3px;
			border: 2px solid $muted;
			content: "";
			display: inline-block;
			height: 23px;
			left: 0;
			top: 3px;
			margin-left: -18px;
			position: absolute;
			transition: 0.3s ease-in-out;
			width: 23px;
			outline: none !important;
		}

		&::after {
			color: $light7;
			display: inline-block;
			font-size: 11px;
			height: 18px;
			left: 0;
			margin-left: -18px;
			padding-left: 3px;
			padding-top: 2px;
			position: absolute;
			top: 0;
			width: 18px;
		}
	}

	input[type="checkbox"] {
		cursor: pointer;
		opacity: 0;
		z-index: 1;
		outline: none !important;

		&:disabled + label {
			opacity: 0.65;
		}
	}

	input[type="checkbox"]:focus + label {
		&::before {
			outline-offset: -2px;
			outline: none;
		}
	}

	input[type="checkbox"]:checked + label {
		&::after {
			content: "";
			position: absolute;
			top: 6px;
			left: 7px;
			display: table;
			width: 7px;
			height: 14px;
			border: 1px solid $dark;
			border-top-width: 0;
			border-left-width: 0;
			transform: rotate(45deg);
		}
	}

	input[type="checkbox"]:disabled + label {
		&::before {
			background-color: $light;
			cursor: not-allowed;
		}
	}
}

.checkbox.checkbox-circle {
	label {
		&::before {
			border-radius: 50%;
		}
	}
}

.checkbox.checkbox-inline {
	margin-top: 0;
}

.checkbox.checkbox-single {
	input {
		height: 18px;
		width: 18px;
		position: absolute;
	}

	label {
		height: 18px;
		width: 18px;

		&:before {
			margin-left: 0;
		}

		&:after {
			margin-left: 0;
		}
	}
}

// Radios
.radio {
	label {
		display: inline-block;
		padding-left: 8px;
		position: relative;
		font-weight: normal;

		&::before {
			-o-transition: border 0.5s ease-in-out;
			-webkit-transition: border 0.5s ease-in-out;
			background-color: $white;
			border-radius: 50%;
			border: 2px solid $muted;
			content: "";
			display: inline-block;
			height: 18px;
			left: 0;
			margin-left: -18px;
			position: absolute;
			transition: border 0.5s ease-in-out;
			width: 18px;
			outline: none !important;
		}

		&::after {
			-moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
			-ms-transform: scale(0, 0);
			-o-transform: scale(0, 0);
			-o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
			-webkit-transform: scale(0, 0);
			-webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
			background-color: $light7;
			border-radius: 50%;
			content: " ";
			display: inline-block;
			height: 10px;
			left: 6px;
			margin-left: -20px;
			position: absolute;
			top: 4px;
			transform: scale(0, 0);
			transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
			width: 10px;
		}
	}

	input[type="radio"] {
		cursor: pointer;
		opacity: 0;
		z-index: 1;
		outline: none !important;

		&:disabled + label {
			opacity: 0.65;
		}
	}

	input[type="radio"]:focus + label {
		&::before {
			outline-offset: -2px;
			outline: 5px auto -webkit-focus-ring-color;
			outline: thin dotted;
		}
	}

	input[type="radio"]:checked + label {
		&::after {
			-ms-transform: scale(1, 1);
			-o-transform: scale(1, 1);
			-webkit-transform: scale(1, 1);
			transform: scale(1, 1);
		}
	}

	input[type="radio"]:disabled + label {
		&::before {
			cursor: not-allowed;
		}
	}
}

.radio.radio-inline {
	margin-top: 0;
}

.radio.radio-single {
	label {
		height: 17px;
	}
}
