.remark-item {

	margin: 0 0 20px;
	border-bottom: 1px solid #d9e3e9;
	padding: 0 0 20px;

	a {
		//color: inherit;
	}

	&__actions {
		float: right;

		a {
			display: inline-block;
			margin-left: 1em;
		}
	}

	&__text {
		clear: both;
		margin-top: 4px;
		font-weight: normal;
		color: $color-text;
	}

}
