.card--koppel-block {
	.card-body {
		padding: 2.25rem 21px;
	}
}

.qr-codes-koppelen {
	&__buttons {
		padding: 0 21px;
		margin: 20px -21px 0;
	}

	&__divider {
		margin: 20px -21px 25px;

		&.qr-codes-koppelen__buttons--top {
			padding-bottom: 25px;
			border-bottom: 4px solid $color-bg-body;
		}

		&.qr-codes-koppelen__buttons--bottom {
			padding-top: 25px;
			margin-bottom: 0;
			border-top: 4px solid $color-bg-body;
		}
	}
}

.card-body .list-items.qr-codes-maintenance-logbook-list__items {
	margin: 0 -15px;

	.list-item {
		border: 0;

		&__button {
			flex-shrink: 0;
		}

		&__date {
			flex-shrink: 0;
		}
	}
}
