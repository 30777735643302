$side-menu-padding-lr: 10 !default;
$active-border-width: 4 !default;

.side-menu {
	background-color: #ffffff;
	overflow: auto;
	padding: 22/14*1em $side-menu-padding-lr/14 * 1em;
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.06), 0 3px 6px rgba(0, 0, 0, 0.15);

	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-content: stretch;
	align-items: stretch;

	a {
		color: $color-text;
		text-decoration: none;

		&:hover, &:focus {
			color: $color-text-light
		}
	}

	&__logo {
		margin: 0 0 40px;
	}
}

.tenant-logo {
	object-fit: contain;
	object-position: center center;

	&--sidebar {
		width: 150px;
		height: 86px;
	}

	&--login {
		width: 207px;
		height: 118px;
	}
}

/* User profile picture + name */
.side-menu__user {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;

	&__image {
		flex: 0 0 auto;

		img {
			border: 1px solid #d9e3e9;
			width: 42px;
			height: 42px;
		}
	}

	&__info {
		padding-left: 10/14*1em;
		flex: 0 1 auto;
		overflow: hidden;
	}

	&__name:not(:only-child),
	&__role {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&__name {
		color: $color-text;
		display: inline-block;
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-word;
	}

	&__role {
		color: $color-text-light;
	}
}

/* Logout */
.side-menu__logout {
	// Button reset
	background-color: transparent;
	border: none;
	text-align: left;

	cursor: pointer;
	padding: 1em 0;

	&__icon {
		vertical-align: middle;

		// Fix percieved position
		margin-top: -1/14*2em;
		margin-left: -2/14*1em;
	}
}

/* Navigation */
.side-menu__nav {
	font-size: 16/14*1em;
	margin-top: 5/16*1em;

	.nav-section {
		border-top: 1px solid #d9e3e9;
		padding-top: 1em;
		padding-left: 0;
		list-style-type: none;

		&:not(:first-child) {
			margin-top: 1em
		}

		&__heading {
			font-weight: bold;
			overflow-wrap: break-word;
			word-wrap: break-word;
			word-break: break-word;
			hyphens: auto;
		}

		li > a {
			cursor: pointer;
			display: block;
			margin: 0 ($side-menu-padding-lr / 16 * -1em);
			padding: 8/16*1em .5em 8/16*1em ($side-menu-padding-lr) / 16 * 1em;

			&.active {
				background-color: $color-grey-light;
				border-left: $active-border-width/16*1em solid currentColor;

				padding-left: ($side-menu-padding-lr - $active-border-width) / 16 * 1em;
			}
		}
	}

	.subnav-parent {
		cursor: pointer;
		padding-top: 8/16*1em;
		padding-bottom: 10px;

		span {
			display: block;
			position: relative;

			.arrow-right-icon {
				text-align: right;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%) rotate(0deg);
				transition: transform .1s ease-in-out;
			}

			&.open {

				.arrow-right-icon {
					transform: rotate(90deg);
					transition: transform .1s ease-in-out;
				}
			}
		}
	}

	.subnav-section {
		list-style: none;
		height: 0;
		padding: 0;
		overflow: hidden;

		li {
			padding-left: 15px;

			a {
				font-size: 12/14*1em;
				line-height: 16/14*1em;
			}
		}

		&.open {
			height: 100%;
		}
	}
}

/* Action buttons on bottom */
.side-menu__actions {
	margin-top: auto;
	padding-left: 0;
	list-style-type: none;

	a, button {
		font-size: 16/14*1em;
	}

	li {
		margin-bottom: $side-menu-padding-lr / 20 * 1em;

		&:last-of-type {
			margin-bottom: 0;
		}

		a {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: center;
		}
	}

	&__icon {
		width: 15/16*1em;
		height: 15/16*1em;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		margin-right: 17/16*1em;

		&--qr {
			width: 25/16*1em;
			height: 25/16*1em;
			margin-left: -4/16*1em;
			margin-right: 11/16*1em;
		}
	}
}


a:focus:not(:focus-visible) {
	color: #505050;
}
