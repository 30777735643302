.add-element {
	color: $color-text-light;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 30px;

	&__title {
		font: inherit;
		margin: 0;
	}

	&__button {
		border: 1px dashed lighten($color-grey-dark, 5%);
		text-align: center;
		height: 55px;
		margin-top: 5px;
		background-color: transparent;
		display: block;
		width: 100%;
		color: #98a6ad;
		cursor: pointer;

		> svg {
			vertical-align: middle;
			height: 25px;
			width: 25px;
		}
	}
}

.add-property-sticky {
	position: relative;
	background: #ffffff;
	bottom: 0;
	margin: 0 -15px;

	h5 {
		margin-top: 10px;
	}

	@supports (position: sticky) {
		position: sticky;
		bottom: -30px;
		margin: 20px -20px 0;
		padding: 10px 0 30px;

		h5 {
			margin-top: 0;
		}
	}

	&--full {
		margin: 0;

		@media screen and (min-width: $bp-tablet-landscape) {
			margin: 0;
			padding: 30px 0;
		}
	}
}
