$card-gutter: 30 !default;
$card-gutter-half: $card-gutter/2;

.widget-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 $card-gutter-half*-1px;
	max-width: calc(100% + #{$card-gutter*1px});
}

.widget {
	background: #ffffff;
	width: 400/14*1em;
	max-width: 50%;
	padding: 20/14*1em;
	margin: 0 $card-gutter-half/14*1em $card-gutter/14*1em;
	border: 1px solid $color-grey;
	position: relative;

	&__content {
		position: relative;
		min-height: 60px;
	}

	&__actions {
		margin-top: 5/14*1em;
		position: relative;
	}

	&--double {
		width: calc(800 / 14 * 1em + #{$card-gutter} / 14 * 1em);
		max-width: 100%;
	}

	@media screen and (max-width: 1100px) {
		max-width: calc(100% + #{$card-gutter});
		width: 100%;
	}
}

.widget__loading {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(255, 255, 255, .75);
	text-align: center;
	color: $secondary;
	display: block;
}

// Widget content
.widget-list {
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;

	li {
		&:not(:last-child) {
			border-bottom: 1px solid $color-grey;
		}

		> a {
			display: block;
			padding: 10/14*1em 0;
			text-decoration: none;
			color: $color-text;

			&:hover {
				color: $color-text-light
			}
		}
	}

	.widget-list-item__arrow-right {
		display: inline-block;
		vertical-align: sub;
		float: right;
	}
}

.widget-counters {
	.single-counter {
		padding-top: 12/14*1em;
		padding-bottom: 12/14*1em;

		&:not(:last-child) {
			border-bottom: 1px solid $color-grey;
		}

		h2 {
			margin-top: 4/14*1em;
			margin-bottom: 0;
			font-size: 1em;
			line-height: 2em;
			font-weight: normal;

			strong {
				display: inline-block;
				min-width: 38/28*1em;
				font-size: 2em;
				line-height: 1em;
				font-feature-settings: "tnum";
			}

			a {
				font-family: $font-primary;
				display: inline-block;
				padding: 0 .5em;
				color: $secondary;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}

		.single-counter__btn {
			margin-top: 6/14*1em;
		}
	}
}

.widget-number-table {
	width: 100%;
	table-layout: fixed;

	th {
		font-weight: normal;
	}

	thead {
		th {
			color: $secondary;
			text-align: center;
		}
	}

	th,
	td {
		vertical-align: bottom;

	}

	&.tr-hover,
	.tr-hover {
		tr:hover {
			background: $light3;
		}

	}

	tbody {
		a {
			display: block;

			&:not(:hover):not(:focus) {
				color: inherit;
			}
		}

		td {
			font-weight: bold;
			font-size: 1.5em;
			line-height: 1em;
			text-align: center;
			padding: .6rem .5rem .4rem;
		}

		th {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: .3rem 0 0;
		}
	}

	tfoot {
		text-align: center;
	}

}

.widget-my-maintenance-link-wrapper {
	color: inherit;
	text-decoration: none;
	display: block;
	width: 100%;
	max-width: 100%;

	&:hover {
		text-decoration: none;
	}
}

.widget-my-maintenance {
	width: 100%;
	max-width: 100%;

	td, th {
		text-align: left;
		vertical-align: middle;
		padding: 15px 0;
		color: $color-text;
		text-decoration: none;

		hyphens: auto;
		word-break: break-word;
		overflow-wrap: break-word;

		&:not(:last-child) {
			padding-right: 10px;
		}
	}

	th {
		white-space: nowrap;
		padding-top: 11px;
	}

	thead {
		th {
			color: $color-text-light;
			font-weight: 400;
			font-family: $font-secondary;
			margin: 10px 0;
			text-rendering: optimizeLegibility;
		}
	}

	tbody {
		td, th {
			border-top: 1px solid $color-grey;
		}
	}

	&.tr-hover,
	.tr-hover {
		tr:hover {
			background: $color-grey-light;
		}
	}

}
