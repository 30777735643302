.element-properties-table-container {
	display: grid;
	grid-column-gap: 1em;
	grid-row-gap: 0;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	align-items: start;
}

table.element-properties-table {
	&, * {
		all: revert;
	}

	border-spacing: 0;
	margin: 0;

	caption {
		display: none;
	}

	th,
	td {
		vertical-align: bottom;
		padding: 0;
		border: none;
		text-align: left;
		width: auto;
		font-weight: normal;
	}

	th {
		width: 18ex;
		padding-right: 1em;
		font-weight: bold;
		-webkit-hyphens: auto;
		hyphens: auto;
		vertical-align: top;

		&.indent {
			padding-left: 1em;
		}
	}

	.element-properties-table-container--pdf & {
		&.element-properties-table--generic th {
			width: 14ex !important;
		}

		&.element-properties-table--technical th {
			width: 18ex !important;
		}
	}
}

.element-properties-table-container.element-properties-table-container--site {
	grid-template-columns: 1fr;

	@media screen and (min-width: 600px) {
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	}

	.element-properties-table {
		table-layout: fixed;
		width: 100%;

		th {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 50% !important;
			max-width: 18ex;
			@media screen and (min-width: 600px) {
				width: 16ex !important;
			}
		}
	}

}
