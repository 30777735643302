@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Roboto'), local('Roboto-Regular'),
	url('/fonts/roboto-v20-latin-ext_latin-regular.woff2') format('woff2'),
	url('/fonts/roboto-v20-latin-ext_latin-regular.woff') format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: local('Roboto Italic'), local('Roboto-Italic'),
	url('/fonts/roboto-v20-latin-ext_latin-italic.woff2') format('woff2'),
	url('/fonts/roboto-v20-latin-ext_latin-italic.woff') format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: local('Roboto Medium'), local('Roboto-Medium'),
	url('/fonts/roboto-v20-latin-ext_latin-500.woff2') format('woff2'),
	url('/fonts/roboto-v20-latin-ext_latin-500.woff') format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
	url('/fonts/roboto-v20-latin-ext_latin-500italic.woff2') format('woff2'),
	url('/fonts/roboto-v20-latin-ext_latin-500italic.woff') format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Roboto Bold'), local('Roboto-Bold'),
	url('/fonts/roboto-v20-latin-ext_latin-700.woff2') format('woff2'),
	url('/fonts/roboto-v20-latin-ext_latin-700.woff') format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
	url('/fonts/roboto-v20-latin-ext_latin-700italic.woff2') format('woff2'),
	url('/fonts/roboto-v20-latin-ext_latin-700italic.woff') format('woff');
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: local('Rubik Medium'), local('Rubik-Medium'),
	url('/fonts/rubik-v9-latin-500.woff2') format('woff2'),
	url('/fonts/rubik-v9-latin-500.woff') format('woff');
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local('Rubik'), local('Rubik-Regular'),
	url('/fonts/rubik-v9-latin-regular.woff2') format('woff2'),
	url('/fonts/rubik-v9-latin-regular.woff') format('woff');
}

@font-face {
	font-family: 'Rubik';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Rubik Bold'), local('Rubik-Bold'),
	url('/fonts/rubik-v9-latin-700.woff2') format('woff2'),
	url('/fonts/rubik-v9-latin-700.woff') format('woff');
}

