:root {
	--element-group-main-name: auto;
	--element-group-main-code: 90px;
	--element-group-main-registration-nr: 160px;
	--element-group-main-place: 160px;
	--element-group-main-floor: 120px;
	--element-group-main-progress: 94px;
	--element-group-main-status: 66px;
	--element-group-main-action: 95px;

	@media screen and (max-width: 1180px) {
		--element-group-main-code: 90px;
		--element-group-main-place: 120px;
		--element-group-main-floor: 110px;
		--element-group-main-registration-nr: 120px;
	}

	@media screen and (min-width: 1280px) {
		--element-group-main-registration-nr: 180px;
	}

	@media screen and (min-width: 1440px) {
		--element-group-main-place: 180px;
		--element-group-main-floor: 180px;
		--element-group-main-registration-nr: 240px;
	}

	@media screen and (min-width: 1600px) {
		--element-group-main-registration-nr: 280px;
	}

	@media screen and (min-width: 1700px) {
		--element-group-main-registration-nr: 300px;
	}
}

.elements-list {
	margin: 0 -20px;
}

.element-list__headers {
	width: 100%;
	color: #8b8b8b;
	min-height: 45px;
	border-bottom: 1px solid #d8d8d8;
	display: flex;
	align-items: flex-end;
	justify-content: stretch;
	padding: 0 21px;

	@media screen and (max-width: 1024px) {
		display: none;
	}
}

.element-list__items {
	margin: 0 20px;
}

.element-group-header {
	padding-left: 15px;

	&--name {
		flex: 1 1 var(--element-group-main-name);
	}

	&--code {
		flex: 0 0 var(--element-group-main-code);
	}

	&--registration-nr {
		flex: 0 0 var(--element-group-main-registration-nr);
	}

	&--place {
		flex: 0 0 var(--element-group-main-place);
	}

	&--floor {
		flex: 0 0 var(--element-group-main-floor);
	}

	&--progress {
		flex: 0 0 var(--element-group-main-progress);
	}

	&--status {
		flex: 0 0 var(--element-group-main-status);
	}

	&--action {
		flex: 0 0 var(--element-group-main-action);
	}
}

.element-group {
	border: 1px solid #d8d8d8;
	border-radius: 3px;
	margin: 20px 0;

	&:hover {
		border: 1px solid #cccccc;
	}
}

.element-group__element {
	display: block;
	min-height: 50px;
	background: #ffffff;

	&.cursor-pointer:hover {
		background: #f3f6f8;
	}

	&:first-child {
		border-top-right-radius: 3px;
		border-top-left-radius: 3px;
	}

	&:first-child {
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	&--sub {
		border-top: 1px solid rgba(216, 216, 216, 0.5);

		&__name {
			position: relative;

			.element-group__element--main & {
				font-weight: 500;
			}
		}
	}
}

.element-group__element {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: flex-start;
	width: 100%;

	&__name {
		flex: 1 1 var(--element-group-main-name);
		margin-right: auto;
		padding: 0 15px;

		word-break: break-word;
		overflow-wrap: break-word;
		hyphens: auto;

		span,
		a {
			display: block;
			padding-top: 1em;
			padding-bottom: .5em;
			color: inherit;
		}
	}

	&__code {
		flex: 0 0 var(--element-group-main-code);
		padding: 1em 0 .5em 15px;
		word-break: break-word;
		overflow-wrap: break-word;
	}

	&__registration-nr {
		flex: 0 0 var(--element-group-main-registration-nr);
		padding: 1em 0 .5em 15px;
		word-break: break-word;
		overflow-wrap: break-word;
		//align-self: center;
	}

	&__place {
		flex: 0 0 var(--element-group-main-place);
		padding: 1em 0 .5em 15px;
		word-break: break-word;
		overflow-wrap: break-word;
		hyphens: auto;
	}

	&__floor {
		flex: 0 0 var(--element-group-main-floor);
		padding: 1em 0 .5em 15px;
		word-break: break-word;
		overflow-wrap: break-word;
		hyphens: auto;
	}

	&__progress {
		flex: 0 0 94px;
		padding: 1em 0 0 15px;
	}

	&__status {
		flex: 0 0 var(--element-group-main-status);
		padding: 12px 0 0 15px;
		text-align: center;
		align-self: stretch;

		.fa-stack {
			margin: 0 auto;
		}
	}

	&__actions {
		flex: 0 0 var(--element-group-main-action);
		padding: 10px 15px;
		min-height: 1px;
		align-self: stretch;

		.element-group:nth-last-child(2):not(:first-child) &,
		.element-group:last-child:not(:first-child) & {
			.dropdown-menu {
				top: auto;
				bottom: 100%;
			}
		}
	}

	@media screen and (max-width: 1024px) {
		display: grid;
		grid-template-columns: 25% 25% 1fr auto;
		padding: 15px;
		grid-auto-rows: auto;
		align-items: start;
		& > * {
			grid-column-start: auto;
			grid-column-end: span 2;
			padding: 0;
		}

		& > [data-label]:before {
			content: attr(data-label) ':\00a0 ';
			display: inline-block;
			vertical-align: top;
		}

		&.element-group__element--sub {
			padding-left: 15px;

			.element-group__element__name:before {
				display: block !important;
				font-weight: 400;
				font-size: 12px;
				line-height: 1;
				content: attr(data-label) !important;
				margin-top: -6px;
				opacity: .75;
			}
		}

		&__place {
			grid-column: 1 / 3;
			grid-row: 2 / 3;
		}

		&__floor {
			grid-column: 1 / 3;
			grid-row: 3 / 4;
		}

		&__registration-nr {
			grid-column: 3 / 5;
			grid-row: 3 / 4;

			&:before {
				content: '' !important;
			}
		}

		&__name {
			grid-column: 1 / 4;
			grid-row: 1 / 2;
			font-weight: 500;
			padding-bottom: .5em;

			span,
			a {
				padding: 0;
			}
		}

		&__status {
			grid-column: 4 / 5;
			grid-row: 1 / 2;
			align-self: start;
			padding: 0;
		}
	}

}

.element-group__element__name__sub-wrapper {
	padding-left: 20px;
	display: block;
	position: relative;

	&:before {
		content: '—';
		position: absolute;
		left: 0;
		opacity: .5;
	}

	@media screen and (max-width: 1024px) {
		padding-left: 0;
		display: inline;

		&:before {
			display: none;
		}
	}
}

.element-group__element__registration-nr {
	ul {
		padding: 0;
		margin: 0;
		display: inline-block;
		list-style-type: none;
	}

	&__type {
		display: inline-block;
		width: 7em;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		vertical-align: middle;
		color: #8b8b8b;
	}

	&__number {
		display: inline-block;
		vertical-align: middle;
	}

}

.toggle-show-registration-nr {
	display: none;
}

@media screen and (min-width: 1025px) and (max-width: 1340px) {

	.toggle-show-registration-nr {
		display: block;
		position: absolute;
		margin-top: -22px;
		user-select: none;
	}

	.elements-list--hide-registration-nr {
		.element-group-header--registration-nr,
		.element-group__element__registration-nr {
			display: none !important;
		}
	}

}
