.text-overflow {
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
	width: 100%;
	overflow: hidden;
}

.text-nowrap {
	white-space: nowrap;
}

// Keeps new lines, but removes whitespace at the beginning or end of the line.
.text-multi-line {
	white-space: pre-line;
}

.cursor-info {
	cursor: help;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default;
}
