// Card styling for Login and Koppel screens
.card {
	&--login-block {
		width: 500/14*1em;

		.card-body {
			@media screen and (min-width: 600px) {
				padding: 36/16*1rem 50/16*1rem;
			}
		}
	}

	&--koppel-block {
		width: 700/14*1em;

		.card-body {
			@media screen and (min-width: 600px) {
				padding: 36/16*1rem 50/16*1rem;
			}
		}
	}
}

.row img {
	height: auto;
}
