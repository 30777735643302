$side-menu-width: 200px !default;
$z-index-side-menu: 10 !default;
@import "../settings";

html, body {
	background-color: $color-bg-body;
	height: 100%;
	width: 100%;
	font-family: $font-primary;
	font-size: 14px;
}

body {
	margin: 0;
}

html {
	position: relative;
	min-height: 100%;
}

img {
	max-width: 100%;
	height: auto;
}

#preview-image-wrapper {
	max-width: 150px;

	.preview-image--inner {
		padding: 0 0;

		img {
			transform-origin: top left;
		}
	}

	&.rotate-90 {
		.preview-image--inner {
			padding: 0 0 30%;

			img {
				transform: rotate(90deg) translateY(-100%);
			}
		}
	}

	&.rotate-180 {
		.preview-image--inner {
			padding: 0 0;

			img {
				transform: rotate(180deg) translate(-100%, -100%);
			}
		}
	}

	&.rotate-270 {
		.preview-image--inner {
			padding: 0 0 30%;

			img {
				transform: rotate(270deg) translateX(-100%);
			}
		}
	}
}

.wrapper-app {
	width: 100%;
	height: 100%;
	padding-left: $side-menu-width;
	transition: padding-left 0.3s ease-in-out;

	@media #{$mq-small-only}{
		padding-left: 0;

		&:not(.mobile-menu-open) {
			.side-menu {
				transform: translate3d(-100%, 0, 0);
			}
		}

		// Prevent flickering/transitions of the mobile menu while loading
		&.menu-initial-state {
			visibility: hidden;

			.hamburger-inner,
			.hamburger-inner:before,
			.hamburger-inner:after,
			.side-menu,
			.content-page {
				transition: none;
			}
		}
	}
}

.content-page,
.side-menu {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}

.side-menu {
	position: fixed;
	height: 100%;
	width: $side-menu-width;
	top: 0;
	left: 0;
	z-index: $z-index-side-menu;
	transform: translate3d(0, 0, 0);
	transition: transform 0.3s ease-in-out;
}

.content-page {
	height: 100%;
	width: 100%;
	padding: 0 30px 30px;
	transition: padding 0.15s ease-in-out;

	@media screen and (max-width: 920px) {
		padding: 0 15px 30px;
	}

	> .content {
		// Overwrite Bootstrap
		padding-bottom: 30px !important;
	}
}


