.private-information {
	button,
	.btn {
		line-height: 18px;
		padding: 0 0.5em;
	}

	&[data-private="true"] {
		.private-information__content,
		.btn-private-information-hide {
			display: none;
		}
	}

	&[data-private="false"] {

		.private-information__placeholder,
		.btn-private-information-show {
			display: none;
		}
	}
}