@charset "UTF-8";
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto"), local("Roboto-Regular"), url("/fonts/roboto-v20-latin-ext_latin-regular.woff2") format("woff2"), url("/fonts/roboto-v20-latin-ext_latin-regular.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Roboto Italic"), local("Roboto-Italic"), url("/fonts/roboto-v20-latin-ext_latin-italic.woff2") format("woff2"), url("/fonts/roboto-v20-latin-ext_latin-italic.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("/fonts/roboto-v20-latin-ext_latin-500.woff2") format("woff2"), url("/fonts/roboto-v20-latin-ext_latin-500.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url("/fonts/roboto-v20-latin-ext_latin-500italic.woff2") format("woff2"), url("/fonts/roboto-v20-latin-ext_latin-500italic.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold"), local("Roboto-Bold"), url("/fonts/roboto-v20-latin-ext_latin-700.woff2") format("woff2"), url("/fonts/roboto-v20-latin-ext_latin-700.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Roboto Bold Italic"), local("Roboto-BoldItalic"), url("/fonts/roboto-v20-latin-ext_latin-700italic.woff2") format("woff2"), url("/fonts/roboto-v20-latin-ext_latin-700italic.woff") format("woff");
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Rubik Medium"), local("Rubik-Medium"), url("/fonts/rubik-v9-latin-500.woff2") format("woff2"), url("/fonts/rubik-v9-latin-500.woff") format("woff");
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Rubik"), local("Rubik-Regular"), url("/fonts/rubik-v9-latin-regular.woff2") format("woff2"), url("/fonts/rubik-v9-latin-regular.woff") format("woff");
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Rubik Bold"), local("Rubik-Bold"), url("/fonts/rubik-v9-latin-700.woff2") format("woff2"), url("/fonts/rubik-v9-latin-700.woff") format("woff");
}
/*
	Vendor library:
	https://github.com/csilva2810/notifier
*/
.notifier-container {
  z-index: 9999999999;
  position: fixed;
  top: 4px;
  right: 4px;
  padding: 4px;
  width: 350px;
  max-width: 98%;
  font-family: "Segoe UI", "Tahoma", "Calibri", "Verdana", sans-serif;
  color: #999999;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.notifier {
  position: relative;
  width: 100%;
  min-height: 62px;
  margin-bottom: 12px;
  padding: 8px;
  background: white;
  border-left: 4px solid #95a5a6;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  opacity: 0;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: all 0.6s cubic-bezier(0.5, -0.5, 0.3, 1.4), opacity 0.6s ease;
  -moz-transition: all 0.6s cubic-bezier(0.5, -0.5, 0.3, 1.4), opacity 0.6s ease;
  -ms-transition: all 0.6s cubic-bezier(0.5, -0.5, 0.3, 1.4), opacity 0.6s ease;
  transition: all 0.6s cubic-bezier(0.5, -0.5, 0.3, 1.4), opacity 0.6s ease;
}

.notifier.shown {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.notifier.shown:hover {
  opacity: 1;
}

.notifier-close {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 16px;
  height: 16px;
  padding: 0;
  text-align: center;
  text-decoration: none;
  color: #aaaaaa;
  font-weight: bold;
  font-size: 16px;
  background: transparent;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  transition: 0.3s ease;
}

.notifier-close:hover,
.notifier-close:focus {
  opacity: 1;
  color: #999999;
  background: #eeeeee;
}

.notifier-img {
  float: left;
  margin-right: 8px;
  vertical-align: middle;
}

.img {
  width: 48px;
  height: 48px;
}

.notifier-title {
  margin: 0;
  padding: 0;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: normal;
}

.notifier-body {
  font-size: 13px;
}

.notifier.info {
  border-left-color: #3498db;
}

.notifier.success {
  border-left-color: #1abc9c;
}

.notifier.warning {
  border-left-color: #f1c40f;
}

.notifier.danger {
  border-left-color: #e74c3c;
}

/*
	Custom styling :
 */
.notifier-container {
  font-family: inherit;
  top: 10px;
  right: 10px;
  width: auto;
  max-width: 100%;
}

.notifier {
  border-radius: 0.3rem;
  border-left-color: #868e96;
  min-height: 2em;
  max-width: 400px;
}

.notifier.info {
  border-left-color: #4a9bee;
}

.notifier.success {
  border-left-color: #009726;
}

.notifier.warning {
  border-left-color: #ffc107;
}

.notifier.danger {
  border-left-color: #c40018;
}

.notifier-close {
  font-size: 16px;
  line-height: normal;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  padding-bottom: 0.25em;
}

.notifier-title:empty {
  display: none;
}

.notifier-body {
  font-size: 0.9rem;
  color: #333333;
  position: relative;
  padding: 0.5em 1.5em 0.5em 0;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}
.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #ffffff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444444;
  line-height: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eeeeee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  cursor: text;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999999;
  margin-top: 5px;
  float: left;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333333;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eeeeee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaaaaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999999;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #dddddd;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444444;
  line-height: 28px;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #dddddd;
  border: none;
  border-left: 1px solid #aaaaaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaaaaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555555;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaaaaa;
  outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}
.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
  border-top: none;
}
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}
.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

body {
  color: #505050;
}

h4, h4.card-title {
  color: #505050;
}

h4, h5 {
  font-weight: normal;
}

h5, .light-heading {
  color: #8b8b8b;
}

.custom-modal h5 {
  font-size: 14px;
}

label, .label {
  font-family: "Roboto", sans-serif;
}

.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6 {
  font-family: "Rubik", sans-serif;
  margin: 10px 0;
  text-rendering: optimizeLegibility;
}

h1 {
  line-height: calc(43/36*1em);
  font-size: 36px;
}

h2 {
  line-height: calc(35/30*1em);
  font-size: 30px;
}

h3 {
  line-height: calc(30/24*1em);
  font-size: 24px;
}

h4 {
  line-height: calc(22/18*1em);
  font-size: 18px;
}

h5 {
  font-size: 15px;
}

h6 {
  font-size: 12px;
}

p {
  line-height: 1.6;
}

ol, ul {
  padding-left: 15px;
}

html, body {
  background-color: #edf1f4;
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

body {
  margin: 0;
}

html {
  position: relative;
  min-height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

#preview-image-wrapper {
  max-width: 150px;
}
#preview-image-wrapper .preview-image--inner {
  padding: 0 0;
}
#preview-image-wrapper .preview-image--inner img {
  transform-origin: top left;
}
#preview-image-wrapper.rotate-90 .preview-image--inner {
  padding: 0 0 30%;
}
#preview-image-wrapper.rotate-90 .preview-image--inner img {
  transform: rotate(90deg) translateY(-100%);
}
#preview-image-wrapper.rotate-180 .preview-image--inner {
  padding: 0 0;
}
#preview-image-wrapper.rotate-180 .preview-image--inner img {
  transform: rotate(180deg) translate(-100%, -100%);
}
#preview-image-wrapper.rotate-270 .preview-image--inner {
  padding: 0 0 30%;
}
#preview-image-wrapper.rotate-270 .preview-image--inner img {
  transform: rotate(270deg) translateX(-100%);
}

.wrapper-app {
  width: 100%;
  height: 100%;
  padding-left: 200px;
  transition: padding-left 0.3s ease-in-out;
}
@media only screen and (max-width: 845px) {
  .wrapper-app {
    padding-left: 0;
  }
  .wrapper-app:not(.mobile-menu-open) .side-menu {
    transform: translate3d(-100%, 0, 0);
  }
  .wrapper-app.menu-initial-state {
    visibility: hidden;
  }
  .wrapper-app.menu-initial-state .hamburger-inner,
.wrapper-app.menu-initial-state .hamburger-inner:before,
.wrapper-app.menu-initial-state .hamburger-inner:after,
.wrapper-app.menu-initial-state .side-menu,
.wrapper-app.menu-initial-state .content-page {
    transition: none;
  }
}

.content-page,
.side-menu {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.side-menu {
  position: fixed;
  height: 100%;
  width: 200px;
  top: 0;
  left: 0;
  z-index: 10;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease-in-out;
}

.content-page {
  height: 100%;
  width: 100%;
  padding: 0 30px 30px;
  transition: padding 0.15s ease-in-out;
}
@media screen and (max-width: 920px) {
  .content-page {
    padding: 0 15px 30px;
  }
}
.content-page > .content {
  padding-bottom: 30px !important;
}

.icon-add-comment {
  transform: translate3d(0, 0, 0);
}

.arrow-right-icon,
.icon-right-arrow {
  color: #8b8b8b;
}

svg:not(:root) {
  fill: currentColor;
}

.dropdown-item.dropdown-item--with-icon {
  padding-left: 2.6em;
  position: relative;
}
.dropdown-item.dropdown-item--with-icon .dropdown-item__icon {
  position: absolute;
  left: 1em;
  top: 6px;
}

.btn.btn-primary {
  background-color: #919fa5;
  border: none;
  transition: background-color 0.15s ease-in-out;
}
.btn.btn-primary:hover {
  background-color: #9fabb0;
  cursor: pointer;
}

button[disabled] {
  cursor: not-allowed !important;
}

.btn.btn-link {
  text-decoration: none;
  color: #8b8b8b;
}
.btn.btn-link:hover, .btn.btn-link:focus {
  text-decoration: underline;
}

.btn.btn.btn-outline-secondary:not(:hover:focus):not(.active) {
  border-color: #d8d8d8;
  color: #8b8b8b;
}
.btn.btn.btn-outline-secondary:not(:hover:focus):not(.active):hover:not(:disabled) {
  color: #ffffff;
}

.btn--input-height {
  padding: 0.469rem 0.75rem;
}

.btn--min-width, .modal .btn {
  min-width: 9.2857142857em;
  margin-bottom: 8px;
}
.btn--min-width-small, .modal.bootbox .modal-footer button {
  min-width: 6.4285714286em;
}
.btn--min-width.btn-link, .modal .btn-link.btn {
  min-width: 8.5714285714em;
}
@media only screen and (min-width: 1200px) {
  .btn--min-width, .modal .btn {
    margin-bottom: 0;
  }
}

table .btn-sm {
  height: 28px;
}

.document-selected {
  border: none;
  color: #505050;
  background-color: #f3f6f8;
  margin: 0 5px 0 0;
  padding: 5px 14px;
  width: auto;
  cursor: pointer;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  max-width: 200px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.document-selected:hover {
  text-decoration: underline;
}
.document-selected--delete {
  border: none;
  margin: 0 5px 0 0;
  padding: 5px 14px;
  color: #505050;
  background-color: #f3f6f8;
  width: auto;
  border-left: 1px solid #d9e3e9;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.document-selected--delete:hover {
  color: #dc3545;
}

.btn {
  max-width: 100%;
}
.btn .btn-icon {
  max-width: 1.4285714286em;
  max-height: 1.4285714286em;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5714285714em;
  margin-top: -0.2142857143em;
}
.btn .btn-icon--pdf {
  margin-top: -5px;
}
.btn .btn-icon--right {
  margin-left: 0.2142857143em;
  margin-right: 0;
  text-align: right;
}
.btn.btn-outline-green {
  color: #009726;
  border-color: #009726;
}
.btn.btn-outline-green.active, .btn.btn-outline-green:hover {
  background-color: #009726 !important;
  border-color: #009726 !important;
  color: #ffffff;
}
.btn.btn-outline-red {
  color: #c40018;
  border-color: #c40018;
}
.btn.btn-outline-red.active, .btn.btn-outline-red:hover {
  background-color: #c40018 !important;
  border-color: #c40018 !important;
  color: #ffffff;
}
.btn.btn-outline-blue {
  color: #4a9bee;
  border-color: #4a9bee;
}
.btn.btn-outline-blue.active, .btn.btn-outline-blue:hover {
  background-color: #4a9bee !important;
  border-color: #4a9bee !important;
  color: #ffffff;
}
.btn svg {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
}
.btn .icon-pdf {
  vertical-align: text-top;
}

.btn--clear-value {
  min-width: 0 !important;
  max-width: 31px !important;
  width: 31px !important;
}

.btn-outline-secondary.focus:not(:focus-visible),
.btn-outline-secondary:focus:not(:focus-visible),
button:focus:not(:focus-visible) {
  outline: none;
  box-shadow: none;
}

.dropdown {
  position: relative;
}
.dropdown:hover {
  z-index: 2;
}

.form-group {
  margin-bottom: 2rem;
}
.form-group .label,
.form-group label {
  color: #8b8b8b;
}

.form-inline {
  position: relative;
}
.form-inline .label,
.form-inline label {
  margin-right: 15px;
}

.form-control {
  padding: 0.5526315789rem 0.75rem;
}

label.error, .error.label, .form-group label.error, .form-inline label.error {
  display: none;
  width: 100%;
  margin: 0.25rem 0 0;
  color: #dc3545;
}

.checkbox-single, .checkbox-single label, .checkbox-single .label {
  display: block;
}

.input-date {
  display: inline-block;
  width: 10.4285714286em;
  min-height: 2.7142857143em;
  text-align: left;
  vertical-align: middle;
  padding-right: 0;
  padding-top: 0.5714285714em;
}
.input-date::placeholder {
  color: #8b8b8b;
}

.select-checkbox select {
  border: none;
  opacity: 0;
  position: absolute;
  top: -20px;
  left: -20px;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  backface-visibility: hidden;
  z-index: 10;
}
.select-checkbox select:hover {
  cursor: pointer;
}
.select-checkbox .select-checkbox-status {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
}
.select-checkbox .select-checkbox-status--done {
  background-color: green;
}
.select-checkbox .select-checkbox-status--warning {
  background-color: yellow;
}
.select-checkbox .select-checkbox-status--error {
  background-color: red;
}

.ck-editor__editable {
  min-height: 150px;
  max-height: 400px;
  padding-bottom: 40px;
}

#gespreksverslagen .ck-editor .ck-editor__top {
  position: initial;
}
#gespreksverslagen .ck-editor__editable {
  min-height: 500px;
  max-height: initial;
}

.ck-editor {
  z-index: 1;
}
.ck-editor .ck-editor__top {
  z-index: 2;
  position: absolute;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.ck-editor .ck-editor__top .ck-toolbar {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0 !important;
}

.js-select-tags-container {
  display: block;
  position: relative;
}

.js-select-tags-container > div {
  list-style: none;
  padding: 0;
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.select2-selection--multiple .select2-selection__choice {
  display: none !important;
}

.select2-results__option {
  padding: 12px 15px !important;
}
.select2-results__option:not(:first-child) {
  border-top: 1px solid #e5e5e5;
}

.select2-results__option[aria-selected=true]::before {
  content: "✓";
  padding-right: 7px;
}

input[readonly][aria-readonly=false] {
  background: #ffffff;
}

label.input-group-text, .input-group-text.label {
  margin: 0;
  color: inherit;
  font-weight: inherit;
}

.input-group .tooltip {
  bottom: 100%;
  max-width: 100%;
}
.input-group .tooltip .arrow {
  left: 50%;
  margin-left: -0.4rem;
}

.tooltip {
  z-index: 800;
}
.tooltip--red .tooltip-inner {
  background: #c40018;
}
.tooltip--red .arrow:before {
  border-top-color: #c40018;
}
.tooltip--warning .tooltip-inner {
  background: #f9bc0b;
  color: #000000;
}
.tooltip--warning .arrow:before {
  border-top-color: #f9bc0b;
}
.tooltip--unit .arrow {
  right: 0;
  left: auto !important;
  margin-right: 1.5rem;
}

.custom-file .btn-file {
  margin-right: 0;
  display: inherit;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}
.custom-file .btn-file:hover {
  background-color: #6c757d;
  color: #ffffff;
}
.custom-file input[type=file] {
  display: none;
}

.form--compact .form-group {
  /*margin-bottom: 2rem;*/
  margin-bottom: 12px;
}
.form--compact .form-group + .row:not(.form-group):last-child {
  margin-top: 2em;
}
.form--compact label, .form--compact .label {
  margin-bottom: 2px;
}

.form-control.is-invalid.warning, .was-validated .form-control:invalid.warning {
  border-color: #ffc107;
  background-image: none;
}

.select2-container .select2-selection--single {
  border: 1px solid #e0e8ed;
  height: 38px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
  padding-left: 12px;
}
.select2-container .select2-selection--single .select2-selection__arrow {
  height: 34px;
  width: 34px;
  right: 3px;
}
.select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: #b2c5d3 transparent transparent transparent;
  border-width: 6px 6px 0 6px;
}

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #b2c5d3 transparent !important;
  border-width: 0 6px 6px 6px !important;
}

.select2-results__option {
  padding: 6px 12px;
}
.select2-results__option[role=group] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.select2-dropdown {
  border: 1px solid #d3dee6;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.select2-container--default .select2-search--dropdown {
  padding: 10px;
  background-color: #f3f6f8;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #d3dee6;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #02c0ce;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #e3eaef;
  color: #313a46;
}
.select2-container--default .select2-results__option[aria-selected=true]:hover {
  background-color: #02c0ce;
  color: #ffffff;
}

.select2-container .select2-selection--multiple {
  min-height: 38px;
  border: 1px solid #d3dee6 !important;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 2px 10px;
}
.select2-container .select2-selection--multiple .select2-search__field {
  margin-top: 7px;
  border: 0;
}
.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: #02c0ce;
  border: 1px solid transparent;
  color: #ffffff;
  border-radius: 3px;
  padding: 0 7px;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove {
  color: #ffffff;
  margin-right: 5px;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #ffffff;
}

.select2-results__options {
  -webkit-overflow-scrolling: touch;
}

.select2-results__option--highlighted[aria-selected] {
  background-color: #919fa5 !important;
}

.select2-results__option[aria-selected=true] {
  background-color: #f2f2f2 !important;
  color: #505050 !important;
}
.select2-results__option[aria-selected=true]:hover {
  background-color: #919fa5 !important;
  color: #ffffff !important;
}

.select2-container--default .select2-search--dropdown {
  background-color: white;
}

.select2-selection--single {
  border: 1px solid #ced4da !important;
}

.general-tooltip .arrow:before {
  border-top-color: #919fa5;
}
.general-tooltip .tooltip-inner {
  background-color: #919fa5;
  color: #ffffff;
  max-width: 450px;
}
.general-tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #919fa5;
}

.checkbox label, .checkbox .label {
  display: inline-block;
  padding: 4px 0 4px 15px;
  position: relative;
  font-weight: normal;
}
.checkbox label::before, .checkbox .label::before {
  background-color: #ffffff;
  border-radius: 3px;
  border: 2px solid #98a6ad;
  content: "";
  display: inline-block;
  height: 23px;
  left: 0;
  top: 3px;
  margin-left: -18px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 23px;
  outline: none !important;
}
.checkbox label::after, .checkbox .label::after {
  color: #797979;
  display: inline-block;
  font-size: 11px;
  height: 18px;
  left: 0;
  margin-left: -18px;
  padding-left: 3px;
  padding-top: 2px;
  position: absolute;
  top: 0;
  width: 18px;
}
.checkbox input[type=checkbox] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.checkbox input[type=checkbox]:disabled + label, .checkbox input[type=checkbox]:disabled + .label {
  opacity: 0.65;
}
.checkbox input[type=checkbox]:focus + label::before, .checkbox input[type=checkbox]:focus + .label::before {
  outline-offset: -2px;
  outline: none;
}
.checkbox input[type=checkbox]:checked + label::after, .checkbox input[type=checkbox]:checked + .label::after {
  content: "";
  position: absolute;
  top: 6px;
  left: 7px;
  display: table;
  width: 7px;
  height: 14px;
  border: 1px solid #313a46;
  border-top-width: 0;
  border-left-width: 0;
  transform: rotate(45deg);
}
.checkbox input[type=checkbox]:disabled + label::before, .checkbox input[type=checkbox]:disabled + .label::before {
  background-color: #e3eaef;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before, .checkbox.checkbox-circle .label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox.checkbox-single input {
  height: 18px;
  width: 18px;
  position: absolute;
}
.checkbox.checkbox-single label, .checkbox.checkbox-single .label {
  height: 18px;
  width: 18px;
}
.checkbox.checkbox-single label:before, .checkbox.checkbox-single .label:before {
  margin-left: 0;
}
.checkbox.checkbox-single label:after, .checkbox.checkbox-single .label:after {
  margin-left: 0;
}

.radio label, .radio .label {
  display: inline-block;
  padding-left: 8px;
  position: relative;
  font-weight: normal;
}
.radio label::before, .radio .label::before {
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #98a6ad;
  content: "";
  display: inline-block;
  height: 18px;
  left: 0;
  margin-left: -18px;
  position: absolute;
  transition: border 0.5s ease-in-out;
  width: 18px;
  outline: none !important;
}
.radio label::after, .radio .label::after {
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -webkit-transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  background-color: #797979;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 10px;
  left: 6px;
  margin-left: -20px;
  position: absolute;
  top: 4px;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  width: 10px;
}
.radio input[type=radio] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.radio input[type=radio]:disabled + label, .radio input[type=radio]:disabled + .label {
  opacity: 0.65;
}
.radio input[type=radio]:focus + label::before, .radio input[type=radio]:focus + .label::before {
  outline-offset: -2px;
  outline: 5px auto -webkit-focus-ring-color;
  outline: thin dotted;
}
.radio input[type=radio]:checked + label::after, .radio input[type=radio]:checked + .label::after {
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio input[type=radio]:disabled + label::before, .radio input[type=radio]:disabled + .label::before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

.radio.radio-single label, .radio.radio-single .label {
  height: 17px;
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  overflow: hidden;
}

.text-nowrap {
  white-space: nowrap;
}

.text-multi-line {
  white-space: pre-line;
}

.cursor-info {
  cursor: help;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.mobile-menu {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  z-index: 100;
  border: none;
  background: no-repeat;
  cursor: pointer;
}
@media only screen and (min-width: 846px) {
  .mobile-menu {
    display: none;
  }
}
.mobile-menu div {
  width: 25px;
  height: 2px;
  background-color: #000000;
  margin: 6px 0;
}

.hamburger {
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #919fa5;
}

.hamburger-box {
  width: 25px;
  height: 30px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: 3px/-2;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 25px;
  height: 3px;
  background-color: #919fa5;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -7px;
}
.hamburger-inner::after {
  bottom: -7px;
}

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.widget-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -15px;
  max-width: calc(100% + 30px);
}

.widget {
  background: #ffffff;
  width: 28.5714285714em;
  max-width: 50%;
  padding: 1.4285714286em;
  margin: 0 1.0714285714em 2.1428571429em;
  border: 1px solid #d8d8d8;
  position: relative;
}
.widget__content {
  position: relative;
  min-height: 60px;
}
.widget__actions {
  margin-top: 0.3571428571em;
  position: relative;
}
.widget--double {
  width: calc(800 / 14 * 1em + 30 / 14 * 1em);
  max-width: 100%;
}
@media screen and (max-width: 1100px) {
  .widget {
    max-width: calc(100% + 30);
    width: 100%;
  }
}

.widget__loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.75);
  text-align: center;
  color: #9a9a9a;
  display: block;
}

.widget-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.widget-list li:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
}
.widget-list li > a {
  display: block;
  padding: 0.7142857143em 0;
  text-decoration: none;
  color: #505050;
}
.widget-list li > a:hover {
  color: #8b8b8b;
}
.widget-list .widget-list-item__arrow-right {
  display: inline-block;
  vertical-align: sub;
  float: right;
}

.widget-counters .single-counter {
  padding-top: 0.8571428571em;
  padding-bottom: 0.8571428571em;
}
.widget-counters .single-counter:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
}
.widget-counters .single-counter h2 {
  margin-top: 0.2857142857em;
  margin-bottom: 0;
  font-size: 1em;
  line-height: 2em;
  font-weight: normal;
}
.widget-counters .single-counter h2 strong {
  display: inline-block;
  min-width: 1.3571428571em;
  font-size: 2em;
  line-height: 1em;
  font-feature-settings: "tnum";
}
.widget-counters .single-counter h2 a {
  font-family: "Roboto", sans-serif;
  display: inline-block;
  padding: 0 0.5em;
  color: #9a9a9a;
}
.widget-counters .single-counter h2 a:hover, .widget-counters .single-counter h2 a:focus {
  text-decoration: underline;
}
.widget-counters .single-counter .single-counter__btn {
  margin-top: 0.4285714286em;
}

.widget-number-table {
  width: 100%;
  table-layout: fixed;
}
.widget-number-table th {
  font-weight: normal;
}
.widget-number-table thead th {
  color: #9a9a9a;
  text-align: center;
}
.widget-number-table th,
.widget-number-table td {
  vertical-align: bottom;
}
.widget-number-table.tr-hover tr:hover,
.widget-number-table .tr-hover tr:hover {
  background: #efefef;
}
.widget-number-table tbody a {
  display: block;
}
.widget-number-table tbody a:not(:hover):not(:focus) {
  color: inherit;
}
.widget-number-table tbody td {
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1em;
  text-align: center;
  padding: 0.6rem 0.5rem 0.4rem;
}
.widget-number-table tbody th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.3rem 0 0;
}
.widget-number-table tfoot {
  text-align: center;
}

.widget-my-maintenance-link-wrapper {
  color: inherit;
  text-decoration: none;
  display: block;
  width: 100%;
  max-width: 100%;
}
.widget-my-maintenance-link-wrapper:hover {
  text-decoration: none;
}

.widget-my-maintenance {
  width: 100%;
  max-width: 100%;
}
.widget-my-maintenance td, .widget-my-maintenance th {
  text-align: left;
  vertical-align: middle;
  padding: 15px 0;
  color: #505050;
  text-decoration: none;
  hyphens: auto;
  word-break: break-word;
  overflow-wrap: break-word;
}
.widget-my-maintenance td:not(:last-child), .widget-my-maintenance th:not(:last-child) {
  padding-right: 10px;
}
.widget-my-maintenance th {
  white-space: nowrap;
  padding-top: 11px;
}
.widget-my-maintenance thead th {
  color: #8b8b8b;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
  margin: 10px 0;
  text-rendering: optimizeLegibility;
}
.widget-my-maintenance tbody td, .widget-my-maintenance tbody th {
  border-top: 1px solid #d8d8d8;
}
.widget-my-maintenance.tr-hover tr:hover,
.widget-my-maintenance .tr-hover tr:hover {
  background: #f3f6f8;
}

.card--login-block {
  width: 35.7142857143em;
}
@media screen and (min-width: 600px) {
  .card--login-block .card-body {
    padding: 2.25rem 3.125rem;
  }
}
.card--koppel-block {
  width: 50em;
}
@media screen and (min-width: 600px) {
  .card--koppel-block .card-body {
    padding: 2.25rem 3.125rem;
  }
}

.row img {
  height: auto;
}

.card {
  margin: 0 0 30px;
  position: relative;
  border: 1px solid #d8d8d8;
}

.card-body {
  padding: 20px;
}

.card-title,
.card-header {
  margin-top: 0 !important;
}

.card-header,
.card-footer {
  background-color: #e3eaef;
  border-bottom-color: #d3dee6;
}

.card-footer {
  border-top-color: #d3dee6;
}

.card-heading-buttons {
  margin-top: -5px;
  float: right;
}
.card-heading-buttons .btn {
  margin-left: 5px;
}
.card-heading-qr-export {
  margin: 0 0 25px;
  text-align: right;
}

.nav-tabs--card {
  color: #646464;
  font-size: 16px;
  line-height: 1.375em;
  font-weight: 400;
}
.nav-tabs--card a {
  color: inherit;
}
.nav-tabs--card .nav-item a {
  padding: 0.5em 0.625em;
}

.nav-tabs--amount {
  font-size: 14px;
}

.toggle-button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.toggle-button__input {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 1;
}

.toggle-button__toggle {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  height: 2em;
  width: 3.4285714286em;
  background: #ffffff;
  border-radius: 7.1428571429em;
  border: 0.2142857143em solid #d8d8d8;
  margin-bottom: 0;
  transition: background-color 0.15s ease-in-out;
}
.toggle-button__toggle:hover {
  cursor: pointer;
}
.toggle-button__toggle::before, .toggle-button__toggle::after {
  content: "";
  position: absolute;
}
.toggle-button__toggle::before {
  background-color: #919fa5;
  height: 2em;
  width: 2em;
  top: -0.2142857143em;
  left: -0.2142857143em;
  border-radius: 100%;
  transition: transform 0.15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.toggle-button__toggle::after {
  top: -0.8571428571em;
  bottom: -0.8571428571em;
  left: -0.8571428571em;
  right: -0.8571428571em;
}

input:checked ~ .toggle-button__toggle,
.toggle-button__toggle.active {
  background-color: #9aff9a;
  border-color: #d8d8d8;
}
input:checked ~ .toggle-button__toggle::before,
.toggle-button__toggle.active::before {
  transform: translate3d(calc(100% - 0.5714285714em), 0, 0);
}

input[disabled] ~ .toggle-button__toggle,
.toggle-button__toggle.disabled {
  cursor: not-allowed;
}

.toggle-button__text-label {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 0.5714285714em;
}
.toggle-button__text-label .toggle-button__text-label__checked {
  color: #505050;
  display: none;
}
input:checked ~ .toggle-button__text-label .toggle-button__text-label__checked {
  display: block;
}
.toggle-button__text-label .toggle-button__text-label__unchecked {
  color: #505050;
  display: block;
}
input:checked ~ .toggle-button__text-label .toggle-button__text-label__unchecked {
  display: none;
}

input:checked ~ .toggle-button__warning, .active ~ .toggle-button__warning {
  display: none;
}

.side-menu {
  background-color: #ffffff;
  overflow: auto;
  padding: 1.5714285714em 1.7857142857em;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.06), 0 3px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}
.side-menu a {
  color: #505050;
  text-decoration: none;
}
.side-menu a:hover, .side-menu a:focus {
  color: #8b8b8b;
}
.side-menu__logo {
  margin: 0 0 40px;
}

.tenant-logo {
  object-fit: contain;
  object-position: center center;
}
.tenant-logo--sidebar {
  width: 150px;
  height: 86px;
}
.tenant-logo--login {
  width: 207px;
  height: 118px;
}

/* User profile picture + name */
.side-menu__user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
}
.side-menu__user__image {
  flex: 0 0 auto;
}
.side-menu__user__image img {
  border: 1px solid #d9e3e9;
  width: 42px;
  height: 42px;
}
.side-menu__user__info {
  padding-left: 0.7142857143em;
  flex: 0 1 auto;
  overflow: hidden;
}
.side-menu__user__name:not(:only-child), .side-menu__user__role {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.side-menu__user__name {
  color: #505050;
  display: inline-block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.side-menu__user__role {
  color: #8b8b8b;
}

/* Logout */
.side-menu__logout {
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  padding: 1em 0;
}
.side-menu__logout__icon {
  vertical-align: middle;
  margin-top: -0.1428571429em;
  margin-left: -0.1428571429em;
}

/* Navigation */
.side-menu__nav {
  font-size: 1.1428571429em;
  margin-top: 0.3125em;
}
.side-menu__nav .nav-section {
  border-top: 1px solid #d9e3e9;
  padding-top: 1em;
  padding-left: 0;
  list-style-type: none;
}
.side-menu__nav .nav-section:not(:first-child) {
  margin-top: 1em;
}
.side-menu__nav .nav-section__heading {
  font-weight: bold;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
.side-menu__nav .nav-section li > a {
  cursor: pointer;
  display: block;
  margin: 0 -1.5625em;
  padding: 0.5em 0.5em 0.5em 1.5625em;
}
.side-menu__nav .nav-section li > a.active {
  background-color: #f3f6f8;
  border-left: 0.25em solid currentColor;
  padding-left: 1.3125em;
}
.side-menu__nav .subnav-parent {
  cursor: pointer;
  padding-top: 0.5em;
  padding-bottom: 10px;
}
.side-menu__nav .subnav-parent span {
  display: block;
  position: relative;
}
.side-menu__nav .subnav-parent span .arrow-right-icon {
  text-align: right;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(0deg);
  transition: transform 0.1s ease-in-out;
}
.side-menu__nav .subnav-parent span.open .arrow-right-icon {
  transform: rotate(90deg);
  transition: transform 0.1s ease-in-out;
}
.side-menu__nav .subnav-section {
  list-style: none;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.side-menu__nav .subnav-section li {
  padding-left: 15px;
}
.side-menu__nav .subnav-section li a {
  font-size: 0.8571428571em;
  line-height: 1.1428571429em;
}
.side-menu__nav .subnav-section.open {
  height: 100%;
}

/* Action buttons on bottom */
.side-menu__actions {
  margin-top: auto;
  padding-left: 0;
  list-style-type: none;
}
.side-menu__actions a, .side-menu__actions button {
  font-size: 1.1428571429em;
}
.side-menu__actions li {
  margin-bottom: 1.25em;
}
.side-menu__actions li:last-of-type {
  margin-bottom: 0;
}
.side-menu__actions li a {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.side-menu__actions__icon {
  width: 0.9375em;
  height: 0.9375em;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  margin-right: 1.0625em;
}
.side-menu__actions__icon--qr {
  width: 1.5625em;
  height: 1.5625em;
  margin-left: -0.25em;
  margin-right: 0.6875em;
}

a:focus:not(:focus-visible) {
  color: #505050;
}

.custom-breadcrumbs {
  padding: 5px 0;
  position: sticky;
  top: 0;
  z-index: 3;
  background: #edf1f4;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0;
}
@media only screen and (min-width: 846px) {
  .custom-breadcrumbs {
    margin: 0 -15px;
  }
}
@media only screen and (min-width: 846px) and (min-width: 920px) {
  .custom-breadcrumbs {
    margin: 0 -30px;
  }
}
.custom-breadcrumbs:before, .custom-breadcrumbs:after {
  display: inline-block;
  vertical-align: middle;
  content: "";
  background: red;
  position: sticky;
  z-index: 2;
  height: 2em;
  width: 30px;
}
@media only screen and (min-width: 846px) and (max-width: 920px) {
  .custom-breadcrumbs:before, .custom-breadcrumbs:after {
    width: 15px;
  }
}
.custom-breadcrumbs:before {
  left: 0;
  background: linear-gradient(to right, #edf1f4 0%, #edf1f4 33%, rgba(237, 241, 244, 0) 100%);
}
.custom-breadcrumbs:after {
  right: 0;
  background: linear-gradient(to left, #edf1f4 0%, #edf1f4 33%, rgba(237, 241, 244, 0) 100%);
}
.custom-breadcrumbs a {
  position: relative;
  color: #505050;
  min-height: 16px;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 0;
  text-decoration: none;
  margin: 0 0.75em 0 0;
}
.custom-breadcrumbs a:last-child {
  font-weight: bold;
}
.custom-breadcrumbs a:not(:last-child):after {
  content: ">";
  display: inline-block;
  text-decoration: none;
  margin: 0 0 0 0.75em;
}
.custom-breadcrumbs a:hover {
  text-decoration: underline;
  border: none;
}

.card-body .list-items {
  margin: 0 -21px;
  overflow: auto;
}
.card-body .list-items .list-items__index {
  padding: 0 21px;
}
.card-body .list-items .list-items__content {
  margin: 0 21px;
}

.list-items__index__column,
.list-item__column {
  padding: 0 15px;
}
.list-items__index__column--systeem,
.list-item__column--systeem {
  flex: 0 0 180px;
  hyphens: auto;
  word-break: break-word;
  overflow-wrap: break-word;
}
@media screen and (max-width: 1280px) {
  .list-items__index__column--systeem,
.list-item__column--systeem {
    flex-basis: 140px;
  }
}
@media screen and (max-width: 1200px) {
  .list-items__index__column--systeem,
.list-item__column--systeem {
    flex-basis: 120px;
  }
}
.list-items__index__column--name,
.list-item__column--name {
  flex: 1 1 60%;
  word-break: break-word;
  overflow-wrap: break-word;
}
.list-items__index__column--auditlog-session,
.list-item__column--auditlog-session {
  width: 60px;
  flex-basis: 60px;
  flex-grow: 1;
}
.list-items__index__column--auditlog-type,
.list-item__column--auditlog-type {
  width: 100px;
  flex-basis: 100px;
  flex-grow: 1;
}
.list-items__index__column--auditlog-text,
.list-item__column--auditlog-text {
  width: 60%;
  flex-basis: 60%;
  flex-grow: 1;
}
.list-items__index__column--auditlog-name,
.list-item__column--auditlog-name {
  width: 10%;
  flex-basis: 10%;
  flex-grow: 1;
}
.list-items__index__column--auditlog-time,
.list-item__column--auditlog-time {
  width: 120px;
  flex-basis: 120px;
  flex-grow: 1;
}
.list-items__index__column--client-name,
.list-item__column--client-name {
  width: 25%;
  flex-basis: 25%;
  flex-grow: 1;
}
.list-items__index__column--client-contract-administrator,
.list-item__column--client-contract-administrator {
  width: 15%;
  flex-basis: 15%;
  flex-grow: 1;
}
.list-items__index__column--client-objects,
.list-item__column--client-objects {
  width: 60%;
  flex-basis: 60%;
  flex-grow: 1;
}
.list-items__index__column--customer-name,
.list-item__column--customer-name {
  width: 15%;
  flex-basis: 15%;
  flex-grow: 1;
}
.list-items__index__column--customer-objects,
.list-item__column--customer-objects {
  width: 40%;
  flex-basis: 40%;
  flex-grow: 1;
}
.list-items__index__column--customer-role,
.list-item__column--customer-role {
  width: 10%;
  flex-basis: 10%;
  flex-grow: 1;
}
.list-items__index__column--customer-company-name,
.list-item__column--customer-company-name {
  width: 15%;
  flex-basis: 15%;
  flex-grow: 1;
}
.list-items__index__column--customer-email-address,
.list-item__column--customer-email-address {
  width: 20%;
  flex-basis: 20%;
  flex-grow: 1;
}
.list-items__index__column--customer-phone-number,
.list-item__column--customer-phone-number {
  width: 10%;
  flex-basis: 10%;
  flex-grow: 1;
}
.list-items__index__column--customer-buttons,
.list-item__column--customer-buttons {
  flex-basis: 200px;
  flex-shrink: 0;
  align-content: flex-end;
}
.list-items__index__column--employee-name,
.list-item__column--employee-name {
  width: 60%;
  flex-basis: 60%;
  flex-grow: 1;
}
.list-items__index__column--employee-hsp-username,
.list-item__column--employee-hsp-username {
  width: 35%;
  flex-basis: 35%;
  flex-grow: 1;
}
.list-items__index__column--employee-working-role,
.list-item__column--employee-working-role {
  width: 30%;
  flex-basis: 30%;
  flex-grow: 1;
}
.list-items__index__column--employee-email-address,
.list-item__column--employee-email-address {
  width: 40%;
  flex-basis: 40%;
  flex-grow: 1;
}
.list-items__index__column--employee-buttons,
.list-item__column--employee-buttons {
  flex-basis: 200px;
  flex-shrink: 0;
  align-content: flex-end;
}
.list-items__index__column--postal,
.list-item__column--postal {
  flex: 0 0 90px;
  padding-right: 0;
}
.list-items__index__column--city,
.list-item__column--city {
  flex: 0 0 180px;
  hyphens: auto;
  word-break: break-word;
  overflow-wrap: break-word;
}
@media screen and (max-width: 1280px) {
  .list-items__index__column--city,
.list-item__column--city {
    flex-basis: 140px;
  }
}
@media screen and (max-width: 1200px) {
  .list-items__index__column--city,
.list-item__column--city {
    flex-basis: 120px;
  }
}
.list-items__index__column--project-code,
.list-item__column--project-code {
  flex: 0 0 170px;
  word-break: break-word;
  overflow-wrap: break-word;
  padding-right: 0;
}
@media screen and (max-width: 1200px) {
  .list-items__index__column--project-code,
.list-item__column--project-code {
    flex-basis: 140px;
  }
}
.list-items__index__column--customer-company,
.list-item__column--customer-company {
  padding-right: 5px !important;
  flex: 0 0 140px;
  word-break: break-word;
  overflow-wrap: break-word;
}
.list-items__index__column--element-name,
.list-item__column--element-name {
  flex: 2 2 60%;
  padding-right: 0;
  hyphens: auto;
  word-break: break-word;
  overflow-wrap: break-word;
}
.list-items__index__column--element-registration-nr,
.list-item__column--element-registration-nr {
  flex: 0 0 140px;
  padding-right: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}
@media screen and (max-width: 1180px) {
  .list-items__index__column--element-registration-nr,
.list-item__column--element-registration-nr {
    flex-basis: 110px;
  }
}
.list-items__index__column--element-code,
.list-item__column--element-code {
  flex: 0 0 140px;
  padding-right: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}
@media screen and (max-width: 1180px) {
  .list-items__index__column--element-code,
.list-item__column--element-code {
    flex-basis: 100px;
  }
}
.list-items__index__column--element-ruimte,
.list-item__column--element-ruimte {
  flex: 0 0 160px;
  padding-right: 0;
}
@media screen and (max-width: 1180px) {
  .list-items__index__column--element-ruimte,
.list-item__column--element-ruimte {
    flex-basis: 120px;
  }
}
.list-items__index__column--element-verdieping,
.list-item__column--element-verdieping {
  flex: 0 0 120px;
  padding-right: 0;
}
@media screen and (max-width: 1180px) {
  .list-items__index__column--element-verdieping,
.list-item__column--element-verdieping {
    flex-basis: 110px;
  }
}
.list-items__index__column--element-ruimte, .list-items__index__column--element-verdieping,
.list-item__column--element-ruimte,
.list-item__column--element-verdieping {
  hyphens: auto;
  word-break: break-word;
  overflow-wrap: break-word;
}
.list-items__index__column--bulk-dropdown,
.list-item__column--bulk-dropdown {
  flex: 0 0 95px;
  padding-left: 5px;
}
.list-items__index__column--element-progress,
.list-item__column--element-progress {
  flex: 0 0 94px;
  padding-right: 0;
}
.list-items__index__column--status,
.list-item__column--status {
  flex: 0 0 66px;
  padding-right: 0;
}
.list-items__index__column--element-buttons,
.list-item__column--element-buttons {
  flex-basis: 150px;
  flex-shrink: 0;
}
.list-items__index__column--bulk-actions,
.list-item__column--bulk-actions {
  flex-basis: 180px;
  flex-grow: 1;
}

.list-items {
  margin: 0 -21px;
}
.list-items a {
  color: #505050;
}
.list-items a:hover {
  text-decoration: none;
}
.list-items__index {
  width: 100%;
  display: flex;
  margin: 0 0 1em 0;
  padding: 0 21px;
  color: #8b8b8b;
  min-height: 45px;
  border-bottom: 1px solid #d8d8d8;
  align-items: stretch;
  justify-content: stretch;
}
.list-items__index:empty {
  min-height: 10px;
}
@media all and (max-width: 1024px) {
  .list-items__index {
    display: none;
  }
}
.list-items__content {
  padding: 0;
}

.list-item__tablet {
  display: none;
  width: 100%;
}
@media all and (max-width: 1024px) {
  .list-item__tablet {
    display: flex;
  }
}

.list-item__desktop {
  display: flex;
  align-items: center;
  width: 100%;
}
@media all and (max-width: 1024px) {
  .list-item__desktop {
    display: none;
  }
}

.list-item {
  width: 100%;
  display: flex;
  min-height: 50px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  margin-top: 20px;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
}
.list-item.list-cell,
.list-item .list-cell {
  padding: 15px;
  list-style: none;
  box-sizing: border-box;
}
.list-item.list-cell__systeem,
.list-item .list-cell__systeem {
  display: flex;
  flex: 0 0 100%;
}
.list-item.list-cell__systeem .list-cell__left, .list-item.list-cell__systeem .list-cell__right,
.list-item .list-cell__systeem .list-cell__left,
.list-item .list-cell__systeem .list-cell__right {
  flex: 1 1 50%;
}
.list-item.list-cell__systeem .list-cell__right,
.list-item .list-cell__systeem .list-cell__right {
  text-align: right;
}
.list-item.list-cell__elementen,
.list-item .list-cell__elementen {
  position: relative;
}
.list-item.list-cell__elementen .list-cell__left, .list-item.list-cell__elementen .list-cell__right,
.list-item .list-cell__elementen .list-cell__left,
.list-item .list-cell__elementen .list-cell__right {
  flex: 1 1 50%;
}
.list-item.list-cell .list-cell__heading,
.list-item .list-cell .list-cell__heading {
  display: none;
  font-weight: bold;
}
@media all and (max-width: 1024px) {
  .list-item.list-cell .list-cell__heading,
.list-item .list-cell .list-cell__heading {
    display: inline-block;
    flex: 1;
    max-width: 150px;
    min-width: 150px;
  }
}
.list-item.list-cell--bulk-dropdown,
.list-item .list-cell--bulk-dropdown {
  margin-top: 0;
}
.list-item.list-cell--bulk-dropdown .icon-plus,
.list-item.list-cell--bulk-dropdown .icon-trash,
.list-item.list-cell--bulk-dropdown .icon-edit,
.list-item .list-cell--bulk-dropdown .icon-plus,
.list-item .list-cell--bulk-dropdown .icon-trash,
.list-item .list-cell--bulk-dropdown .icon-edit {
  margin: -2px 4px 0 0;
}
.list-item .list-row {
  display: flex;
  width: 100%;
}
.list-item .list-row--element-buttons {
  padding-top: 20px;
}
.list-item .list-row--bulk-dropdown {
  margin-top: 10px;
}
.list-item .list-row--bulk-dropdown .icon-plus {
  margin: -2px 4px 0 0;
}
.list-item__column--status {
  justify-content: flex-end;
  text-align: right;
  padding: 11px 15px 11px 0;
  white-space: nowrap;
}
.list-item__column--status i,
.list-item__column--status svg {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  line-height: 22px;
  border-width: 1px;
  border-style: solid;
  border-color: #8b8b8b;
  border-radius: 50%;
}
.list-item__column--status .icon-checked {
  color: #009726;
  border-color: #009726;
}
.list-item__column--status .icon-warning {
  color: #c40018;
  border-color: #c40018;
}
.list-item__column--status .icon-recovered {
  color: #4a9bee;
  border-color: #4a9bee;
}
.list-item--inactive {
  opacity: 0.7;
}
.list-item__content {
  position: relative;
}
.list-item__content--name, .list-item__content--systeem {
  font-weight: 700;
}
.list-item__content--dossier {
  padding-top: 10px;
}
.list-item__content--element {
  padding-bottom: 10px;
  padding-right: 20px;
}
.list-item__content--status {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 200;
}
.list-item__content--status i,
.list-item__content--status svg {
  vertical-align: middle;
  width: 30px;
  height: 30px;
  line-height: 22px;
  border-width: 1px;
  border-style: solid;
  border-color: #8b8b8b;
  border-radius: 50%;
}
.list-item__content--status .icon-checked {
  color: #009726;
  border-color: #009726;
}
.list-item__content--status .icon-warning {
  color: #c40018;
  border-color: #c40018;
}
.list-item__content--status .icon-recovered {
  color: #4a9bee;
  border-color: #4a9bee;
}

.list-items__content-accordion .list-items__content-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 19px -20px 0;
  padding: 0 20px;
  background: #edf1f4;
  text-transform: uppercase;
  color: #919fa5;
}
.list-items__content-accordion .list-items__content-group .content-group__title {
  flex: 1 0 auto;
}
.list-items__content-accordion .list-items__content-group .content-group__toggle {
  flex: 0 0 auto;
}
.list-items__content-accordion .list-items__content-group .content-group__toggle button svg {
  transform: rotate(0);
  transition: transform 0.2s ease;
}
.list-items__content-accordion .list-items__content-group.not-toggleable {
  padding: 5px 26px;
}
.list-items__content-accordion.accordion-item-open .list-items__content-group-body {
  display: block;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  opacity: 1;
}
.list-items__content-accordion.accordion-item-open .content-group__toggle button svg {
  transform: rotate(90deg);
}
.list-items__content-accordion .list-items__content-group-body {
  display: none;
}

.list-items--logboek .list-items__content.list-items__content {
  margin-left: 0;
  margin-right: 0;
}
.list-items--logboek .list-items__content.list-items__content > .list-item {
  border-radius: 0;
}
.list-items--logboek .list-items__content.list-items__content > p:last-child {
  margin: 21px 21px 0;
}
.list-items--logboek .list-item {
  border-radius: 3px;
  min-height: 50px;
}
.list-items--logboek .list-item:not(:first-child) {
  margin-top: -1px;
}
.list-items--logboek .list-item__column {
  padding-top: 12px;
  padding-bottom: 12px;
}
.list-items--logboek .list-item__column--action-icon .arrow-right-icon {
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
:root {
  --element-group-main-name: auto;
  --element-group-main-code: 90px;
  --element-group-main-registration-nr: 160px;
  --element-group-main-place: 160px;
  --element-group-main-floor: 120px;
  --element-group-main-progress: 94px;
  --element-group-main-status: 66px;
  --element-group-main-action: 95px;
}
@media screen and (max-width: 1180px) {
  :root {
    --element-group-main-code: 90px;
    --element-group-main-place: 120px;
    --element-group-main-floor: 110px;
    --element-group-main-registration-nr: 120px;
  }
}
@media screen and (min-width: 1280px) {
  :root {
    --element-group-main-registration-nr: 180px;
  }
}
@media screen and (min-width: 1440px) {
  :root {
    --element-group-main-place: 180px;
    --element-group-main-floor: 180px;
    --element-group-main-registration-nr: 240px;
  }
}
@media screen and (min-width: 1600px) {
  :root {
    --element-group-main-registration-nr: 280px;
  }
}
@media screen and (min-width: 1700px) {
  :root {
    --element-group-main-registration-nr: 300px;
  }
}

.elements-list {
  margin: 0 -20px;
}

.element-list__headers {
  width: 100%;
  color: #8b8b8b;
  min-height: 45px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
  padding: 0 21px;
}
@media screen and (max-width: 1024px) {
  .element-list__headers {
    display: none;
  }
}

.element-list__items {
  margin: 0 20px;
}

.element-group-header {
  padding-left: 15px;
}
.element-group-header--name {
  flex: 1 1 var(--element-group-main-name);
}
.element-group-header--code {
  flex: 0 0 var(--element-group-main-code);
}
.element-group-header--registration-nr {
  flex: 0 0 var(--element-group-main-registration-nr);
}
.element-group-header--place {
  flex: 0 0 var(--element-group-main-place);
}
.element-group-header--floor {
  flex: 0 0 var(--element-group-main-floor);
}
.element-group-header--progress {
  flex: 0 0 var(--element-group-main-progress);
}
.element-group-header--status {
  flex: 0 0 var(--element-group-main-status);
}
.element-group-header--action {
  flex: 0 0 var(--element-group-main-action);
}

.element-group {
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  margin: 20px 0;
}
.element-group:hover {
  border: 1px solid #cccccc;
}

.element-group__element {
  display: block;
  min-height: 50px;
  background: #ffffff;
}
.element-group__element.cursor-pointer:hover {
  background: #f3f6f8;
}
.element-group__element:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.element-group__element:first-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.element-group__element--sub {
  border-top: 1px solid rgba(216, 216, 216, 0.5);
}
.element-group__element--sub__name {
  position: relative;
}
.element-group__element--main .element-group__element--sub__name {
  font-weight: 500;
}

.element-group__element {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.element-group__element__name {
  flex: 1 1 var(--element-group-main-name);
  margin-right: auto;
  padding: 0 15px;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}
.element-group__element__name span,
.element-group__element__name a {
  display: block;
  padding-top: 1em;
  padding-bottom: 0.5em;
  color: inherit;
}
.element-group__element__code {
  flex: 0 0 var(--element-group-main-code);
  padding: 1em 0 0.5em 15px;
  word-break: break-word;
  overflow-wrap: break-word;
}
.element-group__element__registration-nr {
  flex: 0 0 var(--element-group-main-registration-nr);
  padding: 1em 0 0.5em 15px;
  word-break: break-word;
  overflow-wrap: break-word;
}
.element-group__element__place {
  flex: 0 0 var(--element-group-main-place);
  padding: 1em 0 0.5em 15px;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}
.element-group__element__floor {
  flex: 0 0 var(--element-group-main-floor);
  padding: 1em 0 0.5em 15px;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}
.element-group__element__progress {
  flex: 0 0 94px;
  padding: 1em 0 0 15px;
}
.element-group__element__status {
  flex: 0 0 var(--element-group-main-status);
  padding: 12px 0 0 15px;
  text-align: center;
  align-self: stretch;
}
.element-group__element__status .fa-stack {
  margin: 0 auto;
}
.element-group__element__actions {
  flex: 0 0 var(--element-group-main-action);
  padding: 10px 15px;
  min-height: 1px;
  align-self: stretch;
}
.element-group:nth-last-child(2):not(:first-child) .element-group__element__actions .dropdown-menu, .element-group:last-child:not(:first-child) .element-group__element__actions .dropdown-menu {
  top: auto;
  bottom: 100%;
}
@media screen and (max-width: 1024px) {
  .element-group__element {
    display: grid;
    grid-template-columns: 25% 25% 1fr auto;
    padding: 15px;
    grid-auto-rows: auto;
    align-items: start;
  }
  .element-group__element > * {
    grid-column-start: auto;
    grid-column-end: span 2;
    padding: 0;
  }
  .element-group__element > [data-label]:before {
    content: attr(data-label) ": ";
    display: inline-block;
    vertical-align: top;
  }
  .element-group__element.element-group__element--sub {
    padding-left: 15px;
  }
  .element-group__element.element-group__element--sub .element-group__element__name:before {
    display: block !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    content: attr(data-label) !important;
    margin-top: -6px;
    opacity: 0.75;
  }
  .element-group__element__place {
    grid-column: 1/3;
    grid-row: 2/3;
  }
  .element-group__element__floor {
    grid-column: 1/3;
    grid-row: 3/4;
  }
  .element-group__element__registration-nr {
    grid-column: 3/5;
    grid-row: 3/4;
  }
  .element-group__element__registration-nr:before {
    content: "" !important;
  }
  .element-group__element__name {
    grid-column: 1/4;
    grid-row: 1/2;
    font-weight: 500;
    padding-bottom: 0.5em;
  }
  .element-group__element__name span,
.element-group__element__name a {
    padding: 0;
  }
  .element-group__element__status {
    grid-column: 4/5;
    grid-row: 1/2;
    align-self: start;
    padding: 0;
  }
}

.element-group__element__name__sub-wrapper {
  padding-left: 20px;
  display: block;
  position: relative;
}
.element-group__element__name__sub-wrapper:before {
  content: "—";
  position: absolute;
  left: 0;
  opacity: 0.5;
}
@media screen and (max-width: 1024px) {
  .element-group__element__name__sub-wrapper {
    padding-left: 0;
    display: inline;
  }
  .element-group__element__name__sub-wrapper:before {
    display: none;
  }
}

.element-group__element__registration-nr ul {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style-type: none;
}
.element-group__element__registration-nr__type {
  display: inline-block;
  width: 7em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  color: #8b8b8b;
}
.element-group__element__registration-nr__number {
  display: inline-block;
  vertical-align: middle;
}

.toggle-show-registration-nr {
  display: none;
}

@media screen and (min-width: 1025px) and (max-width: 1340px) {
  .toggle-show-registration-nr {
    display: block;
    position: absolute;
    margin-top: -22px;
    user-select: none;
  }

  .elements-list--hide-registration-nr .element-group-header--registration-nr,
.elements-list--hide-registration-nr .element-group__element__registration-nr {
    display: none !important;
  }
}
.card-body .list-stacked-items {
  margin: 0 -21px;
  overflow: inherit;
}
.card-body .list-stacked-items .list-stacked-items__index {
  padding: 0 20px;
}
.card-body .list-stacked-items .list-stacked-items__content {
  margin: 0 20px;
}
.card-body .list-stacked-items .list-stacked-items__content > p:only-child {
  margin-top: 20px;
}

.list-stacked-items a {
  color: #505050;
}
.list-stacked-items a:hover {
  text-decoration: none;
}
.list-stacked-items__index {
  color: #8b8b8b;
  min-height: 35px;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}
.list-stacked-items__index__column h5 {
  white-space: nowrap;
}
.list-stacked-items__index__column * {
  line-height: 15px;
}
.list-stacked-items__index:empty {
  min-height: 10px;
}
.list-stacked-items .list-stacked-item {
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
}
.list-stacked-items .list-stacked-item:last-of-type {
  border-bottom: none;
}
.list-stacked-items .list-stacked-item__column--action-type {
  color: #8b8b8b;
}
.list-stacked-items .list-stacked-item__column:not(:last-child) {
  padding-right: 10px;
}
.list-stacked-items .list-stacked-item__column, .list-stacked-items__index__column {
  position: relative;
  padding: 15px 0;
  vertical-align: top;
  flex: 1 0 auto;
  max-width: 85%;
}
.list-stacked-items .list-stacked-item__column--action-icon, .list-stacked-items__index__column--action-icon {
  text-align: right;
  padding: 5px 0;
  flex: 0 0 20px;
  margin-left: auto;
}
.list-stacked-items .list-stacked-item__column--action-icon .arrow-right-icon, .list-stacked-items__index__column--action-icon .arrow-right-icon {
  height: 20px;
  width: 13px;
  color: #8b8b8b;
}
.list-stacked-items .list-stacked-item__column--action-type, .list-stacked-items__index__column--action-type {
  min-width: 130px;
  flex: 1 1 auto;
}
.list-stacked-items .list-stacked-item__column--action-system, .list-stacked-items__index__column--action-system {
  flex: 0 0 160px;
  word-break: break-word;
  overflow-wrap: break-word;
}
@media screen and (max-width: 1400px) {
  .list-stacked-items .list-stacked-item__column--action-system, .list-stacked-items__index__column--action-system {
    flex-basis: 120px;
  }
}
.list-stacked-items .list-stacked-item__column--action-system small, .list-stacked-items__index__column--action-system small {
  display: block;
  line-height: 1em;
}
.list-stacked-items .list-stacked-item__column--action-name, .list-stacked-items__index__column--action-name {
  flex: 0 0 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media screen and (max-width: 1400px) {
  .list-stacked-items .list-stacked-item__column--action-name, .list-stacked-items__index__column--action-name {
    flex-basis: 140px;
  }
}
.list-stacked-items .list-stacked-item__column--dossier-number, .list-stacked-items__index__column--dossier-number {
  flex: 0 0 90px;
}
.list-stacked-items .list-stacked-item__column--internal-reference, .list-stacked-items__index__column--internal-reference {
  flex: 0 0 120px;
}
.list-stacked-items .list-stacked-item__column--action-date, .list-stacked-items__index__column--action-date {
  white-space: nowrap;
  flex: 0 0 100px;
  text-align: center;
  padding-right: 10px;
}
.list-stacked-items .list-stacked-item__column--action-continue, .list-stacked-items__index__column--action-continue {
  color: #8b8b8b;
  width: 72px;
  text-align: right;
  white-space: nowrap;
  flex: 0 0 72px;
}
.list-stacked-items .list-stacked-item__column--action-buttons, .list-stacked-items__index__column--action-buttons {
  color: #8b8b8b;
  width: 260px;
  text-align: right;
  white-space: nowrap;
}
.list-stacked-items .list-stacked-item__column--action-short-buttons, .list-stacked-items__index__column--action-short-buttons {
  color: #8b8b8b;
  text-align: right;
  white-space: nowrap;
  flex: 0 0 200px;
}
.list-stacked-items .list-stacked-item__column--action-long-buttons, .list-stacked-items__index__column--action-long-buttons {
  color: #8b8b8b;
  text-align: right;
  white-space: nowrap;
  flex: 0 0 370px;
}
.list-stacked-items .list-stacked-item__column--action-definitive, .list-stacked-items__index__column--action-definitive {
  margin-right: auto;
  white-space: nowrap;
  text-align: center;
  padding-right: 10px;
  flex: 0 0 120px;
}
.list-stacked-items .list-stacked-item__column--checkbox, .list-stacked-items__index__column--checkbox {
  align-self: center;
  width: 5%;
  min-width: 40px;
  flex: 0 0 auto;
  margin: 0;
}
.list-stacked-items .list-stacked-item__column--qr-code-canvas, .list-stacked-items__index__column--qr-code-canvas {
  align-self: center;
  width: 10%;
  min-width: 80px;
  flex: 0 0 auto;
}
.list-stacked-items .list-stacked-item__column--qr-code-canvas canvas, .list-stacked-items__index__column--qr-code-canvas canvas {
  vertical-align: middle;
}
.list-stacked-items .list-stacked-item__column--qr-code-link, .list-stacked-items__index__column--qr-code-link {
  color: #8b8b8b;
  align-self: center;
  width: 35%;
  min-width: 200px;
  flex: 1 0 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.list-stacked-items .list-stacked-item__column--qr-code-linked-object, .list-stacked-items__index__column--qr-code-linked-object {
  color: #8b8b8b;
  align-self: center;
  width: 30%;
  min-width: 130px;
  flex: 1 0 auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.list-stacked-items .list-stacked-item__column--logboek-heading, .list-stacked-items__index__column--logboek-heading {
  flex: 1 1 auto;
  max-width: calc(100% - 20px);
}
.list-stacked-items .list-stacked-item__column__report-title {
  display: block;
  padding: 0.5rem 0;
}

@media screen and (min-width: 1440px) {
  .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-name,
.list-stacked-items--gesloten-onderhoud .list-stacked-items__index__column--action-name {
    width: 200px;
    flex-basis: 200px;
  }
  .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-system,
.list-stacked-items--gesloten-onderhoud .list-stacked-items__index__column--action-system {
    width: 200px;
    flex-basis: 200px;
  }
}
@media screen and (min-width: 1600px) {
  .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-name,
.list-stacked-items--gesloten-onderhoud .list-stacked-items__index__column--action-name {
    width: 220px;
    flex-basis: 220px;
  }
  .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-system,
.list-stacked-items--gesloten-onderhoud .list-stacked-items__index__column--action-system {
    width: 240px;
    flex-basis: 240px;
  }
}
@media screen and (min-width: 1241px) {
  .list-stacked-items__sm-only {
    display: none !important;
  }
}
@media screen and (max-width: 1240px) {
  .card-body .list-stacked-items--gesloten-onderhoud {
    overflow: hidden;
  }

  .list-stacked-items--gesloten-onderhoud .list-stacked-items__index {
    display: none;
  }

  @supports (display: grid) {
    .list-stacked-items--gesloten-onderhoud .list-stacked-item {
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-column-gap: 20px;
    }
    .list-stacked-items--gesloten-onderhoud .list-stacked-item__column {
      min-width: auto;
      max-width: none;
      width: auto;
      padding-top: 5px;
      padding-bottom: 5px;
      color: #505050;
      text-align: left;
      white-space: normal;
    }
    .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-buttons:last-child, .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-short-buttons:last-child, .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-long-buttons:last-child {
      grid-column: 2;
      grid-row: 1/10;
      justify-self: end;
    }
    .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-buttons:last-child .btn-group, .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-short-buttons:last-child .btn-group, .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-long-buttons:last-child .btn-group {
      flex-direction: column;
      justify-self: stretch;
    }
    .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-buttons:last-child .btn-group .btn, .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-short-buttons:last-child .btn-group .btn, .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-long-buttons:last-child .btn-group .btn {
      border-radius: 0;
      margin: 0 !important;
    }
    .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-buttons:last-child .btn-group .btn:first-child, .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-short-buttons:last-child .btn-group .btn:first-child, .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-long-buttons:last-child .btn-group .btn:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-buttons:last-child .btn-group .btn:last-child, .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-short-buttons:last-child .btn-group .btn:last-child, .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-long-buttons:last-child .btn-group .btn:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-buttons:last-child .dropdown:not(:only-child), .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-short-buttons:last-child .dropdown:not(:only-child), .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-long-buttons:last-child .dropdown:not(:only-child) {
      margin: 0 !important;
    }
    .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-buttons:last-child .dropdown:not(:only-child) .dropdown-toggle, .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-short-buttons:last-child .dropdown:not(:only-child) .dropdown-toggle, .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-long-buttons:last-child .dropdown:not(:only-child) .dropdown-toggle {
      display: block;
      margin: 0;
      width: 100%;
      border-radius: 0;
    }
    .list-stacked-items--gesloten-onderhoud .list-stacked-item__column:not(:last-child) {
      padding-left: 120px;
    }
    .list-stacked-items--gesloten-onderhoud .list-stacked-item__column--action-definitive span {
      color: #8b8b8b;
    }
    .list-stacked-items--gesloten-onderhoud .list-stacked-item__column[data-label]::before {
      position: absolute;
      top: 5px;
      left: 0;
      content: attr(data-label);
      color: #505050;
      width: 120px;
      display: inline-block;
      text-align: left;
    }
    .list-stacked-items--gesloten-onderhoud .list-stacked-item .list-stacked-item__column__report-title {
      display: inline-block;
      padding: 0;
    }
  }
}
.list-accordion-items {
  max-width: 100%;
}

.accordion-item {
  display: block;
  background: #ffffff;
  border-bottom: 1px solid #d8d8d8;
}
.accordion-item__remarks {
  display: block;
  margin-top: 10px;
}
.accordion-item__heading {
  position: relative;
  display: block;
  max-width: 100%;
}
.accordion-item__heading button {
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  display: block;
  text-align: left;
  padding: 10px 20px 10px 0;
  min-height: 4.4285714286em;
}
.accordion-item__heading button:hover {
  cursor: pointer;
}
.accordion-item__heading__export-checkbox {
  float: left;
  display: inline-block;
  width: 40px;
  padding: 20px 0;
  text-align: center;
  margin: 0 0 0 -20px;
}
.accordion-item__heading__export-checkbox + button {
  width: calc(100% - 40px);
}
.accordion-item__heading__title, .accordion-item__heading__subtitle {
  display: block;
}
.accordion-item__heading__subtitle {
  color: #8b8b8b;
}
.accordion-item__heading__title {
  color: #505050;
}
.accordion-item__heading__title__separator {
  padding-left: 5px;
  padding-right: 5px;
}
.accordion-item__heading__title__title-text span {
  display: inline-block;
}
.accordion-item__heading__deadline {
  position: relative;
  color: #8b8b8b;
  padding: 10px 0 0;
}
@media screen and (min-width: 1024px) {
  .accordion-item__heading__deadline {
    position: absolute;
    right: 50px;
    top: 20px;
    color: #8b8b8b;
    padding: 0;
  }
}
.accordion-item__heading__image {
  position: relative;
  padding: 10px 0 0;
}
.accordion-item__heading .arrow-right-icon {
  position: absolute;
  right: 0;
  top: 20px;
  transition: transform 0.15s ease-in-out;
  color: #8b8b8b;
}
.accordion-item__body {
  max-width: 100%;
}
.accordion-item__body .accordion-item__body__wrapper {
  padding-bottom: 15px;
  display: none;
}

.accordion-item-open > .accordion-item__heading .arrow-right-icon {
  transform: rotate(90deg);
}
.accordion-item-open > .accordion-item__body > .accordion-item__body__wrapper {
  display: block;
}
.accordion-item-open .select2-container {
  left: auto !important;
  right: 0 !important;
}
.accordion-item-open .select2-container .select2-dropdown {
  position: relative !important;
  margin-right: 50px !important;
  max-width: 550px;
}

.accordion-item__body__wrapper > *:last-child {
  margin-bottom: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.logboek-heading {
  display: block;
}
.logboek-heading__subtitle {
  display: block;
  color: #8b8b8b;
}
.logboek-heading__title {
  color: #505050;
}
.logboek-heading__separator {
  padding-left: 5px;
  padding-right: 5px;
}

.list-accordion-items--logboek .accordion-item {
  margin-left: -21px;
  margin-right: -21px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  min-height: 50px;
}
.list-accordion-items--logboek .accordion-item + .accordion-item {
  margin-top: -1px;
}
.list-accordion-items--logboek > .accordion-item {
  border-radius: 0;
}
.list-accordion-items--logboek .remark-overview .remark-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 5px;
}

.accordion-item-row, .instruction-item__row {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #d8d8d8;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.accordion-item-row p:last-child, .instruction-item__row p:last-child, .accordion-item-row ol:last-child, .instruction-item__row ol:last-child, .accordion-item-row ul:last-child, .instruction-item__row ul:last-child {
  margin-bottom: 0;
}
.accordion-item-row:last-child, .instruction-item__row:last-child {
  padding-bottom: 0;
}
@media screen and (max-width: 650px) {
  .accordion-item-row, .instruction-item__row {
    flex-wrap: wrap;
  }
}

.instruction-item__row {
  flex-wrap: wrap;
}
.instruction-item__row:last-child {
  padding-bottom: 10px;
}

.instruction-item__row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  flex-wrap: nowrap;
  max-width: 100%;
}
.instruction-item__title {
  flex: 1 1 auto;
  padding-right: 15px;
}
.instruction-item__subtitle {
  flex: 1 1 auto;
  text-align: right;
  margin-left: auto;
  max-width: 40%;
  font-weight: 500;
}
.instruction-item__value {
  flex: 1 1 auto;
  text-align: right;
  margin-left: auto;
  max-width: 40%;
}
.instruction-item .answerline__heading {
  font-weight: bold;
  margin-top: 10px;
}
@media screen and (max-width: 600px) {
  .instruction-item__row {
    flex-direction: column;
  }
  .instruction-item__value {
    text-align: left;
    margin-left: 0;
  }
}

.instruction-item-comment {
  margin-top: 8px;
  width: 100%;
}
.instruction-item-comment__heading {
  display: block;
  font-style: italic;
}

.accordion-item.accordion-item--nested {
  margin-left: 0;
  margin-right: 0;
}

.accordion-item.accordion-item--instruction {
  min-height: 28px;
}
.accordion-item.accordion-item--instruction .accordion-item__heading button {
  min-height: 3.5714285714em;
}
.accordion-item.accordion-item--instruction .arrow-right-icon {
  top: 15px;
  height: 21px;
  width: 10px;
}
.accordion-item.accordion-item--instruction:not(:first-child) {
  margin-top: 5px;
}

.accordion-item.accordion-item--grey {
  background-color: #f3f6f8;
}

.list-search {
  margin: 0 -20px 45px;
  padding: 0 20px 0;
  border-bottom: 3px solid #edf1f4;
}
.list-search:not(:first-child) {
  padding-top: 25px;
  border-top: 3px solid #edf1f4;
  border-bottom: 3px solid #edf1f4;
}

.add-element {
  color: #8b8b8b;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}
.add-element__title {
  font: inherit;
  margin: 0;
}
.add-element__button {
  border: 1px dashed #9fabb0;
  text-align: center;
  height: 55px;
  margin-top: 5px;
  background-color: transparent;
  display: block;
  width: 100%;
  color: #98a6ad;
  cursor: pointer;
}
.add-element__button > svg {
  vertical-align: middle;
  height: 25px;
  width: 25px;
}

.add-property-sticky {
  position: relative;
  background: #ffffff;
  bottom: 0;
  margin: 0 -15px;
}
.add-property-sticky h5 {
  margin-top: 10px;
}
@supports (position: sticky) {
  .add-property-sticky {
    position: sticky;
    bottom: -30px;
    margin: 20px -20px 0;
    padding: 10px 0 30px;
  }
  .add-property-sticky h5 {
    margin-top: 0;
  }
}
.add-property-sticky--full {
  margin: 0;
}
@media screen and (min-width: 1024px) {
  .add-property-sticky--full {
    margin: 0;
    padding: 30px 0;
  }
}

.modal {
  z-index: 999 !important;
  overscroll-behavior: contain;
}
.modal button.close > span {
  font-size: 40px;
}
.modal .modal-dialog {
  max-width: 90vw;
}
.modal .modal-content {
  padding: 15px 70px 25px 70px;
  border-radius: 4px;
  border: none;
}
.modal .modal-header {
  border-bottom: none;
}
.modal .modal-header .modal-title {
  font-size: 18px;
  color: #505050;
  margin-top: 6px;
}
.modal .modal-body {
  padding-top: 2px;
}
@media screen and (max-width: 768px) {
  .modal .modal-body .form-group input {
    margin-bottom: 5px;
  }
}
.modal.modal-no-padding .modal-content {
  padding: 0;
}
.modal-backdrop.show {
  opacity: 0.3;
}

.modal.bootbox .modal-dialog {
  width: 600px;
}
.modal.bootbox .bootbox-close-button {
  font-size: 40px;
}
.modal.bootbox .modal-header {
  display: block;
}
.modal.bootbox .modal-body:first-child {
  padding-top: 30px;
}
.modal.bootbox .modal-footer {
  padding-top: 0.7em;
  border-top: none;
  flex-direction: row-reverse;
}
.modal.bootbox .modal-footer button.btn-link {
  margin-left: 5px;
}

.image-modal {
  text-align: center;
}
.image-modal .modal-dialog {
  width: auto;
  display: inline-block;
}

.modal-open .content-page {
  overflow: hidden;
}

.modal-backdrop {
  text-align: center;
  color: #ffffff;
  height: 100vh;
  display: grid;
  z-index: 998 !important;
}
.modal-backdrop .loading-spinner {
  margin: auto;
}

.modal-blockingoverlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadeInModalBlockingOverlay 1s ease-in-out;
  pointer-events: auto;
  visibility: visible;
  cursor: wait;
  opacity: 1;
  text-align: center;
  color: #ffffff;
}

@keyframes fadeInModalBlockingOverlay {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.logboek-filter .filter-row,
.elementen-filter .filter-row {
  width: 100%;
  padding-bottom: 1.7857142857em;
  margin-bottom: 1.7857142857em;
  border-bottom: 1px solid #d8d8d8;
}
.logboek-filter .filter-row--checkbox-row .checkbox,
.elementen-filter .filter-row--checkbox-row .checkbox {
  margin-bottom: 0.3571428571em;
}

.list-filtered {
  position: relative;
  display: block;
  min-height: 65px;
  margin-top: 20px;
  padding-bottom: 10px;
}
.list-filtered__no-filters {
  margin: 6px 0;
  color: #8b8b8b;
}
.list-filtered.small-filters {
  padding-top: 0;
  margin-top: 0;
  border-top: none;
}

.list-filtered-bar {
  position: relative;
  border-top: 1px solid #d8d8d8;
  display: block;
  min-height: 65px;
  margin-top: 20px;
  padding-top: 15px;
  padding-bottom: 10px;
}
.list-filtered-bar__no-filters {
  margin: 6px 0;
  color: #8b8b8b;
}
.list-filtered-bar.small-filters {
  padding-top: 0;
  margin-top: 0;
  border-top: none;
}

.tag-selected {
  border: none;
  color: #505050;
  background-color: #f3f6f8;
  margin: 0 5px 5px 0;
  padding: 5px 14px;
  width: auto;
  cursor: pointer;
  transition: box-shadow 0.1s ease-in-out, background-color 0.1s ease-in-out;
  box-shadow: inset 0 0 0 1px #e6ecf0;
}
.tag-selected__remove {
  color: #6a6a6a !important;
  display: inline-block;
  margin-right: 8px;
  font-size: 20px;
  line-height: 0.7em;
  vertical-align: middle;
}
.tag-selected__remove:hover {
  text-decoration: none;
}
.tag-selected:not(.tag-selected--not-clickable):hover, .tag-selected:not(.tag-selected--not-clickable):focus, .tag-selected:not(.tag-selected--not-clickable):active {
  box-shadow: inset 0 0 0 1px #d8d8d8;
}
.tag-selected:not(.tag-selected--not-clickable):active:hover {
  background-color: #d3dee5;
}
.tag-selected.tag-selected--not-clickable {
  cursor: default;
}
.select--small-tags ~ .js-select-tags-container .tag-selected {
  display: block;
  width: 100%;
  flex: 1 0 100%;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
  text-align: left;
}

.select2-selection--multiple {
  background-image: url("/images/icon-search.svg");
  background-position: right 12px center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
}
.brigit-design .select2-selection--multiple {
  background-image: url("/src/static/images/icon-search.svg");
}

.blockingoverlay,
.menublockingoverlay {
  display: block;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  transition: opacity 0.5s 0.3s ease-in-out;
  z-index: -1;
}

html[data-loading=true] .blockingoverlay {
  pointer-events: auto;
  z-index: 9999;
  visibility: visible;
  cursor: wait;
  bottom: 0;
  right: 0;
  opacity: 1;
}

@media only screen and (max-width: 845px) {
  .mobile-menu-open .content-page .menublockingoverlay {
    pointer-events: auto;
    z-index: 4;
    visibility: visible;
    bottom: 0;
    right: 0;
    opacity: 1;
  }
}
.instruction-rule {
  padding: 0;
  border-top: 1px solid #d9e3e9;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
}
@media screen and (min-width: 1024px) {
  .instruction-rule {
    flex-wrap: nowrap;
  }
}
.instruction-rule__title {
  font-size: 1rem;
  line-height: 1.4285714286em;
  font-weight: 400;
  padding: 0;
  margin: 0;
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  width: 50%;
}
.instruction-rule__title .btn-link,
.instruction-rule__title button {
  display: inline-block;
  max-width: 100%;
  padding: 14px 1em 7px 0;
  margin: 0;
  border: none;
  background: none;
  text-align: inherit;
  font: inherit;
  color: inherit;
  cursor: pointer;
  white-space: normal;
}
.instruction-rule__title .btn-link svg,
.instruction-rule__title button svg {
  color: inherit;
  display: inline-block;
  vertical-align: top;
  width: 18px;
  margin-left: 2px;
}
.instruction-rule__title .info-tooltip {
  display: inline-block;
  vertical-align: -14px;
  position: relative;
  top: -4px;
}
.instruction-rule__subtitle {
  font-size: 1rem;
  line-height: 1.4285714286em;
  font-weight: 500;
  text-align: right;
  margin: 20px 0 0;
}
.instruction-rule__group-toggle {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  height: 50px;
  width: 50px;
  text-align: right;
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;
}
.instruction-rule__select {
  order: 0;
  flex: 0 0 auto;
  align-self: auto;
  margin: 7px 0 7px auto;
}
.instruction-rule__select .select2-selection__rendered {
  text-align: center;
}
.instruction-rule__select .vue-multiselect-wrapper {
  width: 100%;
  flex: 0 0 auto;
}
.instruction-rule__select .vue-multiselect-wrapper .multiselect__placeholder {
  white-space: nowrap;
}
.instruction-rule__checkbox {
  order: 0;
  flex: 0 0 auto;
  align-self: auto;
  padding: 0 0 8px;
  margin: 0;
}
.instruction-rule__text-input {
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
}
.instruction-rule__text-input:last-child {
  padding-bottom: 8px;
}
.instruction-rule__number-input {
  flex: 0 0 auto;
  margin: 0;
  padding: 0 0 8px;
}
.instruction-rule__file-input {
  flex: 0 0 auto;
  margin: 0;
  padding: 0 0 8px;
}
.instruction-rule__file-input .custom-file .btn-file {
  margin-right: 0;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}
.instruction-rule__file-input .custom-file .btn-file:hover {
  background-color: #6c757d;
  color: #ffffff;
}
.instruction-rule__file-input .custom-file input[type=file] {
  display: none;
}
.instruction-rule__file-input .file-action-label {
  margin-right: 0;
}
.instruction-rule__readonly .input-group .form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  padding: 0.375rem 0.75rem;
}
.instruction-rule__readonly .input-group .form-control-plaintext.is-invalid {
  color: #dc3545;
}
.instruction-rule__textarea {
  flex: 0 0 auto;
  width: 100%;
  margin: 3px 0 1em auto;
}
.instruction-rule__checkbox, .instruction-rule__text-input, .instruction-rule__number-input, .instruction-rule__file-input, .instruction-rule__select, .instruction-rule__textarea, .instruction-rule__readonly {
  text-align: right;
  overflow: visible;
  position: relative;
}
.instruction-rule__checkbox select.form-control,
.instruction-rule__checkbox .input-group, .instruction-rule__text-input select.form-control,
.instruction-rule__text-input .input-group, .instruction-rule__number-input select.form-control,
.instruction-rule__number-input .input-group, .instruction-rule__file-input select.form-control,
.instruction-rule__file-input .input-group, .instruction-rule__select select.form-control,
.instruction-rule__select .input-group, .instruction-rule__textarea select.form-control,
.instruction-rule__textarea .input-group, .instruction-rule__readonly select.form-control,
.instruction-rule__readonly .input-group {
  width: 12em;
  max-width: 12em;
}
.instruction-rule__checkbox .form-inline, .instruction-rule__text-input .form-inline, .instruction-rule__number-input .form-inline, .instruction-rule__file-input .form-inline, .instruction-rule__select .form-inline, .instruction-rule__textarea .form-inline, .instruction-rule__readonly .form-inline {
  padding: 8px 0;
}
.instruction-rule__checkbox .form-group, .instruction-rule__text-input .form-group, .instruction-rule__number-input .form-group, .instruction-rule__file-input .form-group, .instruction-rule__select .form-group, .instruction-rule__textarea .form-group, .instruction-rule__readonly .form-group {
  flex: 0 1 auto;
  max-width: 100%;
  flex-wrap: nowrap;
}
.instruction-rule__select {
  overflow: visible;
}
.instruction-rule__select .input-group--select-nenCode {
  width: 20em;
  max-width: 20em;
}
.instruction-rule__select .input-group--select-nenCode .vue-multiselect-wrapper .multiselect__option {
  white-space: normal !important;
}
.instruction-rule__text-input {
  grid-area: input;
  width: auto;
  max-width: none;
  margin-left: auto;
}
.instruction-rule__text-input .form-group {
  margin-left: auto;
}
.instruction-rule__text-input--range .form-group {
  margin-left: 0;
}
.instruction-rule__text-input--range .input-group {
  width: 9em;
}
.instruction-rule__input__saved-icon {
  position: absolute;
  left: calc(100% + 6px);
  top: 12px;
  color: #009726;
  opacity: 0;
  animation: savedAnimation 4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.instruction-rule__input__saved-icon svg {
  fill: #009726;
}
.instruction-rule[data-nvt=true] .instruction-rule__input__saved-icon {
  display: none;
}
.instruction-rule__saved-status--saving {
  margin: 70px 0 8px auto;
  width: 20px;
  height: 20px;
  background: url("/images/spinner.svg");
  animation: fadeIn 1s cubic-bezier(1, 0, 0, 1);
}
.brigit-design .instruction-rule__saved-status--saving {
  background-image: url("/src/static/images/spinner.svg");
}
.instruction-rule[data-nvt=true] .instruction-rule__saved-status--saving {
  margin-top: 18px;
}
.instruction-rule__saved-status--success {
  margin: 70px 0 8px auto;
  opacity: 0;
  animation: savedAnimation 4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  overflow: hidden;
  color: #009726;
  display: none;
}
.instruction-rule__saved-status--success svg {
  fill: #009726;
}
.instruction-rule[data-nvt] .instruction-rule__saved-status--success {
  margin-top: 18px;
  display: block;
}
@keyframes savedAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  5% {
    opacity: 1;
    transform: scale(1);
  }
  95% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}
.instruction-rule__comment {
  border-left: 4px solid #efefef;
  padding-left: 10px;
  margin-bottom: 14px;
}
.instruction-rule__comment a {
  display: inline-block;
  margin-right: 1em;
}
.instruction-rule__group-title {
  color: #646464;
}
.instruction-rule .btn-group .btn {
  min-width: 4.1428571429em;
  white-space: nowrap;
}

.instruction-rule {
  display: grid;
  width: 100%;
  max-width: 100%;
  overflow: visible;
  grid-template-areas: "title title nvt save" "addcomment input input save" "comment comment comment filler";
  grid-template-columns: 180px 1fr 100px 30px;
}
@media screen and (max-width: 700px) {
  .instruction-rule {
    grid-template-columns: 110px 1fr 100px 30px;
  }
}
@media screen and (max-width: 599px) {
  .instruction-rule {
    grid-template-areas: "title nvt save" "input input save" "addcomment addcomment filler" "comment comment filler";
    grid-template-columns: 1fr 100px 30px;
  }
}
.instruction-rule > div:first-child {
  grid-area: title;
}
.instruction-rule__title {
  grid-area: title;
  width: auto;
  max-width: none;
}
.instruction-rule__input, .instruction-rule__checkbox, .instruction-rule__number-input, .instruction-rule__textarea {
  grid-area: input;
  width: auto;
  max-width: 100%;
  margin-left: auto;
  align-self: self-end;
}
.instruction-rule__input .form-group, .instruction-rule__checkbox .form-group, .instruction-rule__number-input .form-group, .instruction-rule__textarea .form-group {
  margin-left: auto;
}
.instruction-rule__saved-status, .instruction-rule__spinner {
  grid-area: save;
  flex-grow: 0;
}
.instruction-rule__check {
  grid-area: save;
  flex-grow: 0;
}
.instruction-rule__nvt-button {
  grid-area: nvt;
  margin: auto 0 8px auto;
  padding-top: 14px;
  white-space: nowrap;
}
.instruction-rule__add-comment {
  grid-area: addcomment;
  margin-bottom: 7px;
  padding-top: 8px;
}
.instruction-rule__comment {
  grid-area: comment;
}
@media screen and (max-width: 575px) {
  .instruction-rule .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
  }
}

.instruction-group-title {
  display: flex;
  padding: 0;
}
.instruction-group-title__total,
.instruction-group-title h5 {
  line-height: 1.4285714286em;
  text-transform: uppercase;
  font-weight: 500;
  color: #646464;
}
.instruction-group-title h5 {
  margin: 0;
  padding: 14px 1em 14px 0;
}
.instruction-group-title__total {
  margin-left: auto;
  margin-right: 1em;
  align-self: center;
  font-size: 15px;
}
.instruction-group-title + .arrow-right-icon {
  top: 24px;
}

.remark-item {
  margin: 0 0 20px;
  border-bottom: 1px solid #d9e3e9;
  padding: 0 0 20px;
}
.remark-item__actions {
  float: right;
}
.remark-item__actions a {
  display: inline-block;
  margin-left: 1em;
}
.remark-item__text {
  clear: both;
  margin-top: 4px;
  font-weight: normal;
  color: #505050;
}

table .no-padding-left {
  padding-left: 0;
}
table td {
  vertical-align: middle;
}
table td .attachment {
  display: inline-flex;
  align-items: center;
  margin: 2px 0;
}

.user-overview td {
  vertical-align: middle;
}
.user-overview td svg {
  vertical-align: middle;
}
.user-overview td.tool-inspection-overdue {
  color: #dc3545;
}
.user-overview td.tool-inspection {
  color: #f0ad4e;
}
.user-overview td.tools-comment {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}

.table-container {
  overflow: auto;
  max-width: 100%;
  clear: both;
}

.tenant-details-table td {
  word-break: break-word;
  overflow-wrap: break-word;
}
.tenant-details-table__col-th {
  width: 180px;
}
.tenant-details-table__col-td {
  width: 400px;
  max-width: 50vw;
}

.wysiwyg-editor-output {
  overflow: auto;
}
.wysiwyg-editor-output table {
  width: auto;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.wysiwyg-editor-output table th, .wysiwyg-editor-output table td {
  border: 1px solid #d8d8d8;
  padding: 5px;
}
.wysiwyg-editor-output table th {
  vertical-align: bottom;
}
.wysiwyg-editor-output table td {
  vertical-align: middle;
}

.detail-comment a {
  text-decoration: underline;
}
.detail-comment > *:last-child {
  margin-bottom: 0;
}

.detail-image--container {
  max-width: 200px;
  display: inline-block;
}

.private-information button,
.private-information .btn {
  line-height: 18px;
  padding: 0 0.5em;
}
.private-information[data-private=true] .private-information__content,
.private-information[data-private=true] .btn-private-information-hide {
  display: none;
}
.private-information[data-private=false] .private-information__placeholder,
.private-information[data-private=false] .btn-private-information-show {
  display: none;
}

.nav-tabs {
  position: relative;
  padding-bottom: 0;
  margin-bottom: -1px;
  border-left: 1px solid #d8d8d8;
  border-top-left-radius: 4px;
  border-bottom: none;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: visible;
  z-index: 2;
  -webkit-overflow-scrolling: auto;
}
.nav-tabs .nav-item {
  display: inline-block;
  position: relative;
  margin-left: -1px;
  margin-bottom: 0;
}
.nav-tabs .nav-link {
  border: 1px solid #d8d8d8;
  border-bottom: none;
  transition: background-color 0.15s ease-in-out;
}
.nav-tabs .nav-link:not(.active):hover {
  border-color: #d8d8d8;
  background-color: #e9eff2;
}
.nav-tabs .nav-link.active {
  border-bottom: 1px solid white;
}
.nav-tabs + .tab-content {
  position: relative;
}
.nav-tabs + .tab-content > .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card--koppel-block .card-body {
  padding: 2.25rem 21px;
}

.qr-codes-koppelen__buttons {
  padding: 0 21px;
  margin: 20px -21px 0;
}
.qr-codes-koppelen__divider {
  margin: 20px -21px 25px;
}
.qr-codes-koppelen__divider.qr-codes-koppelen__buttons--top {
  padding-bottom: 25px;
  border-bottom: 4px solid #edf1f4;
}
.qr-codes-koppelen__divider.qr-codes-koppelen__buttons--bottom {
  padding-top: 25px;
  margin-bottom: 0;
  border-top: 4px solid #edf1f4;
}

.card-body .list-items.qr-codes-maintenance-logbook-list__items {
  margin: 0 -15px;
}
.card-body .list-items.qr-codes-maintenance-logbook-list__items .list-item {
  border: 0;
}
.card-body .list-items.qr-codes-maintenance-logbook-list__items .list-item__button {
  flex-shrink: 0;
}
.card-body .list-items.qr-codes-maintenance-logbook-list__items .list-item__date {
  flex-shrink: 0;
}

.nen2767-matrix-wrapper {
  overflow: auto;
}
.nen2767-matrix-wrapper.nen2767-matrix-wrapper--logbook {
  margin: 0 -7px;
}
@media screen and (max-width: 900px) {
  .nen2767-matrix-wrapper.nen2767-matrix-wrapper--logbook {
    margin: 0 -15px;
  }
}

table.nen2767-matrix {
  min-width: 100%;
}
table.nen2767-matrix thead th {
  vertical-align: bottom;
}
.nen2767-matrix-wrapper--logbook table.nen2767-matrix tr {
  border-bottom: 1px solid #efefef;
  transition: background 0.05s ease-in-out;
}
table.nen2767-matrix tbody tr:hover {
  background: #efefef;
}
table.nen2767-matrix th,
table.nen2767-matrix td {
  text-align: center;
}
table.nen2767-matrix th:first-child,
table.nen2767-matrix td:first-child {
  text-align: right;
  width: 50%;
  padding-right: 15px;
}
.nen2767-matrix-wrapper--logbook table.nen2767-matrix th:first-child,
.nen2767-matrix-wrapper--logbook table.nen2767-matrix td:first-child {
  line-height: 1.1em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}
@media screen and (min-width: 1280px) {
  .nen2767-matrix-wrapper--logbook table.nen2767-matrix th:first-child,
.nen2767-matrix-wrapper--logbook table.nen2767-matrix td:first-child {
    text-indent: -6px;
  }
}
.nen2767-matrix-wrapper.nen2767-matrix-wrapper--pdf table.nen2767-matrix th,
.nen2767-matrix-wrapper.nen2767-matrix-wrapper--pdf table.nen2767-matrix td {
  width: auto;
}
table.nen2767-matrix td.red,
table.nen2767-matrix td.yellow,
table.nen2767-matrix td.green {
  color: #8b8b8b;
}
table.nen2767-matrix td.red.selected,
table.nen2767-matrix td.yellow.selected,
table.nen2767-matrix td.green.selected {
  color: #000000;
}
table.nen2767-matrix td.selected.red {
  background: #f1556c;
}
table.nen2767-matrix td.selected.yellow {
  background: #f9bc0b;
}
table.nen2767-matrix td.selected.green {
  background: #0acf97;
}

.rule-group__content__answer__line.rule-group__content__answer__line--nen2767 {
  display: block;
}
.rule-group__content__answer__line.rule-group__content__answer__line--nen2767 .rule-group__content__answer__line__label--nen2767 {
  display: none;
}
.rule-group__content__answer__line.rule-group__content__answer__line--nen2767 .rule-group__content__answer__line__value {
  min-width: 100%;
  max-width: 100%;
}

.po-input-table-wrapper {
  overflow: auto;
}

table.po-input-table {
  min-width: 100%;
}
table.po-input-table thead tr {
  border-bottom: 2px solid #d8d8d8;
}
table.po-input-table tbody tr {
  border-bottom: 1px solid #d8d8d8;
  transition: background 0.05s ease-in-out;
}
.po-input-table-wrapper--logbook table.po-input-table tbody tr:hover {
  background: #efefef;
}
table.po-input-table thead tr th,
table.po-input-table tbody tr td {
  text-align: center;
  padding: 0.2em 5px;
  line-height: 1.1em;
}
table.po-input-table thead tr th:first-child,
table.po-input-table tbody tr td:first-child {
  text-align: right;
}
.po-input-table-wrapper.po-input-table-wrapper--pdf table.po-input-table thead tr th,
.po-input-table-wrapper.po-input-table-wrapper--pdf table.po-input-table tbody tr td {
  width: auto;
}

.rule-group__content__answer__line.rule-group__content__answer__line--highLow {
  display: block;
}
.rule-group__content__answer__line.rule-group__content__answer__line--highLow .rule-group__content__answer__line__label--highLow {
  display: none;
}
.rule-group__content__answer__line.rule-group__content__answer__line--highLow .rule-group__content__answer__line__value {
  min-width: 100%;
  max-width: 100%;
}

.gas-security-input-table-wrapper table {
  table-layout: auto;
  width: 100%;
  min-width: 100%;
}

.gw-input-table-wrapper {
  break-inside: avoid;
}
.gw-input-table-wrapper table {
  table-layout: fixed;
}
.gw-input-table-wrapper table thead tr {
  border-top: 1px solid #d8d8d8;
  border-bottom: 2px solid #d8d8d8;
}

.gw-input-table-wrapper.po-input-table-wrapper--pdf {
  width: fit-content;
  overflow: auto;
}
.gw-input-table-wrapper.po-input-table-wrapper--pdf table {
  width: 100%;
  min-width: 100%;
}
.gw-input-table-wrapper.po-input-table-wrapper--pdf table tr .unit-symbol {
  border-left: 1px solid #d8d8d8;
  padding-right: 0;
  padding-left: 5px;
  text-align: left;
}
.gw-input-table-wrapper.po-input-table-wrapper--pdf table thead tr:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
}
.gw-input-table-wrapper.po-input-table-wrapper--pdf table tbody tr {
  border-bottom: 1px solid #d8d8d8;
}
.gw-input-table-wrapper.po-input-table-wrapper--pdf table tbody tr:first-child {
  border-top: 2px solid #d8d8d8;
}
.gw-input-table-wrapper.po-input-table-wrapper--pdf table tr.dividing-table-header-row th {
  text-align: right !important;
}
.gw-input-table-wrapper.po-input-table-wrapper--pdf table tr.dividing-table-header-row th:not(.dividing-border-right) {
  border-right: none;
}
.gw-input-table-wrapper.po-input-table-wrapper--pdf table th.dividing-border-right {
  border-right: 1px solid #d8d8d8;
}
.gw-input-table-wrapper.po-input-table-wrapper--pdf table th.boundary-value__label,
.gw-input-table-wrapper.po-input-table-wrapper--pdf table td.boundary-value__label {
  width: auto !important;
  border-right: 1px solid #d8d8d8;
}
.gw-input-table-wrapper.po-input-table-wrapper--pdf table td.boundary-value {
  overflow: clip;
  width: 45px !important;
  border-left: 1px solid #d8d8d8;
}

table sub {
  margin-bottom: -4px;
  display: inline-block;
}

.rule-group__content__answer__line--table .rule-group__content__answer__line__label--table {
  display: none;
}
.rule-group__content__answer__line--table .rule-group__content__answer__line__value {
  max-width: 100%;
}
.rule-group__content__answer__line--table .table-general-answer {
  overflow: auto;
}
.rule-group__content__answer__line--table .table-general-answer table {
  width: auto;
}
.rule-group__content__answer__line--table .table-general-answer table tr th,
.rule-group__content__answer__line--table .table-general-answer table tr td {
  padding: 5px;
}
.rule-group__content__answer__line--table .table-general-answer table tr th:first-child,
.rule-group__content__answer__line--table .table-general-answer table tr td:first-child {
  border-right: 1px solid #d8d8d8;
}
.rule-group__content__answer__line--table .table-general-answer table thead tr th {
  text-align: left;
  white-space: pre-line;
}
.rule-group__content__answer__line--table .table-general-answer table tbody tr:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
}

details.accordion-details {
  border-top: 1px solid #cccccc;
  margin: 0 0 0.5em;
}
details.accordion-details summary {
  text-transform: uppercase;
  padding: 0.5em 0;
  font-size: 14px;
  color: #505050;
  list-style: none;
}
details.accordion-details summary::-webkit-details-marker {
  display: none;
}
details.accordion-details summary::marker {
  content: "";
  display: none;
}
details.accordion-details[open] > summary {
  margin-bottom: 1em;
}
details.accordion-details .summary-arrow {
  float: right;
  position: relative;
  top: 4px;
  fill: currentColor;
  transition: transform 0.2s ease;
}
details.accordion-details[open] > summary .summary-arrow {
  transform: rotate(90deg);
}

@media screen and (min-width: 480px) {
  .element-detail-header {
    display: grid;
    grid-template-areas: "title edit" "info image" "extra-info extra-info";
    grid-template-columns: 1fr 120px;
    grid-gap: 0 1rem;
  }
}
@media screen and (min-width: 600px) {
  .element-detail-header {
    grid-template-columns: 1fr 200px;
    grid-template-areas: "title edit" "info image" "extra-info image";
  }
}
.element-detail-header__title {
  grid-area: title;
}
.element-detail-header__edit {
  grid-area: edit;
}
.element-detail-header__info {
  grid-area: info;
}
.element-detail-header__image {
  grid-area: image;
}
.element-detail-header__extra-info {
  grid-area: extra-info;
}

.element-properties-table-container {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-items: start;
}

table.element-properties-table {
  border-spacing: 0;
  margin: 0;
}
table.element-properties-table, table.element-properties-table * {
  all: revert;
}
table.element-properties-table caption {
  display: none;
}
table.element-properties-table th,
table.element-properties-table td {
  vertical-align: bottom;
  padding: 0;
  border: none;
  text-align: left;
  width: auto;
  font-weight: normal;
}
table.element-properties-table th {
  width: 18ex;
  padding-right: 1em;
  font-weight: bold;
  -webkit-hyphens: auto;
  hyphens: auto;
  vertical-align: top;
}
table.element-properties-table th.indent {
  padding-left: 1em;
}
.element-properties-table-container--pdf table.element-properties-table.element-properties-table--generic th {
  width: 14ex !important;
}
.element-properties-table-container--pdf table.element-properties-table.element-properties-table--technical th {
  width: 18ex !important;
}

.element-properties-table-container.element-properties-table-container--site {
  grid-template-columns: 1fr;
}
@media screen and (min-width: 600px) {
  .element-properties-table-container.element-properties-table-container--site {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
.element-properties-table-container.element-properties-table-container--site .element-properties-table {
  table-layout: fixed;
  width: 100%;
}
.element-properties-table-container.element-properties-table-container--site .element-properties-table th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 50% !important;
  max-width: 18ex;
}
@media screen and (min-width: 600px) {
  .element-properties-table-container.element-properties-table-container--site .element-properties-table th {
    width: 16ex !important;
  }
}

[v-cloak] {
  display: none;
}