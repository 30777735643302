.detail-comment {
	a {
		text-decoration: underline;
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}

.detail-image {

	&--container {
		max-width: 200px;
		display: inline-block;
	}
}
