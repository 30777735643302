.po-input-table-wrapper {
	overflow: auto;
}

table.po-input-table {
	min-width: 100%;

	thead tr {
		border-bottom: 2px solid $color-grey;
	}

	tbody tr {
		border-bottom: 1px solid $color-grey;
		transition: background .05s ease-in-out;

		.po-input-table-wrapper--logbook &:hover {
			background: $light3;
		}
	}

	thead tr th,
	tbody tr td {
		text-align: center;
		padding: .2em 5px;
		line-height: 1.1em;

		&:first-child {
			text-align: right;
		}

		.po-input-table-wrapper.po-input-table-wrapper--pdf & {
			width: auto;
		}
	}
}

.rule-group__content__answer__line.rule-group__content__answer__line--highLow {
	display: block;

	.rule-group__content__answer__line__label--highLow {
		display: none;
	}

	.rule-group__content__answer__line__value {
		min-width: 100%;
		max-width: 100%;
	}
}

.gas-security-input-table-wrapper table {
	table-layout: auto;
	width: 100%;
	min-width: 100%;
}

.gw-input-table-wrapper {
	break-inside: avoid;

	table {
		table-layout: fixed;

		thead tr {
			border-top: 1px solid $color-grey;
			border-bottom: 2px solid $color-grey;
		}
	}
}

.gw-input-table-wrapper.po-input-table-wrapper--pdf {
	width: fit-content;
	overflow: auto;

	table {
		width: 100%;
		min-width: 100%;

		tr {
			.unit-symbol {
				border-left: 1px solid $color-grey;
				padding-right: 0;
				padding-left: 5px;
				text-align: left;
			}
		}

		thead tr {
			&:not(:last-child) {
				border-bottom: 1px solid $color-grey;
			}
		}

		tbody tr {
			border-bottom: 1px solid $color-grey;

			&:first-child {
				border-top: 2px solid $color-grey;
			}
		}

		tr.dividing-table-header-row th {
			text-align: right !important;

			&:not(.dividing-border-right) {
				border-right: none;
			}
		}

		th.dividing-border-right {
			border-right: 1px solid $color-grey;
		}

		th.boundary-value__label,
		td.boundary-value__label {
			width: auto !important;
			border-right: 1px solid $color-grey;
		}

		td.boundary-value {
			overflow: clip;
			width: 45px !important;
			border-left: 1px solid $color-grey;
		}
	}
}

table sub {
	margin-bottom: -4px;
	display: inline-block;
}

.rule-group__content__answer__line--table {
	.rule-group__content__answer__line__label--table {
		display: none;
	}

	.rule-group__content__answer__line__value {
		max-width: 100%;
	}

	.table-general-answer {
		overflow: auto;

		table {
			width: auto;

			tr th,
			tr td {
				padding: 5px;

				&:first-child {
					border-right: 1px solid $color-grey;
				}
			}

			thead tr th {
				text-align: left;
				white-space: pre-line;
			}

			tbody tr {
				&:not(:last-child) {
					border-bottom: 1px solid $color-grey;
				}
			}
		}
	}
}
