////
/// @since 1.0.0
/// @group List Stacked Items
/// @author Remco Dekker
////

/// Stacked Items in 'card body' element
.card-body .list-stacked-items {
	margin: 0 -21px;
	overflow: inherit;

	.list-stacked-items__index {
		padding: 0 20px;
	}

	.list-stacked-items__content {
		margin: 0 20px;

		& > p:only-child {
			margin-top: 20px;
		}
	}
}

/// General List Stacked Items style
.list-stacked-items {
	a {
		color: $color-text;

		&:hover {
			text-decoration: none;
		}
	}

	// Index Rows
	&__index {
		color: $color-text-light;
		min-height: 35px;
		border-bottom: 1px solid $color-grey;

		display: flex;
		flex-direction: row;
		justify-content: stretch;

		&__column {
			h5 {
				white-space: nowrap;
			}

			* {
				line-height: 15px;
			}
		}

		&:empty {
			min-height: 10px;
		}
	}

	/// Single list item
	.list-stacked-item {
		width: 100%;
		border-bottom: 1px solid $color-grey;
		border-radius: 3px;

		display: flex;
		flex-direction: row;
		justify-content: stretch;
		align-items: center;

		&:last-of-type {
			border-bottom: none;
		}

		&__column {
			&--action-type {
				color: $color-text-light;
			}

			&:not(:last-child) {
				padding-right: 10px;
			}
		}
	}

	/// Single list item
	/// maps to:
	/// - .list-stacked-item
	/// - .list-stacked-item__index
	/// - .list-stakced-item__index__column
	.list-stacked-item,
	&__index {
		&__column {
			position: relative;
			padding: 15px 0;
			vertical-align: top;
			flex: 1 0 auto;
			max-width: 85%;

			&--action-icon {
				text-align: right;
				padding: 5px 0;
				flex: 0 0 20px;
				margin-left: auto;

				.arrow-right-icon {
					height: 20px;
					width: 13px;
					color: $color-text-light;
				}
			}

			&--action-type {
				min-width: 130px;
				flex: 1 1 auto;
			}

			&--action-system {
				flex: 0 0 160px;
				word-break: break-word;
				overflow-wrap: break-word;

				@media screen and (max-width: 1400px) {
					flex-basis: 120px;
				}

				small {
					display: block;
					line-height: 1em;
				}
			}

			&--action-name {
				flex: 0 0 180px;

				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				@media screen and (max-width: 1400px) {
					flex-basis: 140px;
				}
			}

			&--dossier-number {
				flex: 0 0 90px;
			}

			&--internal-reference {
				flex: 0 0 120px;
			}

			&--action-date {
				white-space: nowrap;

				flex: 0 0 100px;
				text-align: center;
				padding-right: 10px;
			}

			&--action-continue {
				color: $color-text-light;
				width: 72px;
				text-align: right;
				white-space: nowrap;

				flex: 0 0 72px;
			}

			&--action-buttons {
				color: $color-text-light;
				width: 260px;
				text-align: right;
				white-space: nowrap;
			}

			&--action-short-buttons {
				color: $color-text-light;
				text-align: right;
				white-space: nowrap;
				flex: 0 0 200px;
			}

			&--action-long-buttons {
				color: $color-text-light;
				text-align: right;
				white-space: nowrap;
				flex: 0 0 370px;
			}

			&--action-definitive {
				margin-right: auto;
				white-space: nowrap;
				text-align: center;
				padding-right: 10px;
				flex: 0 0 120px;
			}

			&--checkbox {
				align-self: center;
				width: 5%;
				min-width: 40px;
				flex: 0 0 auto;
				margin: 0;
			}

			&--qr-code-canvas {
				align-self: center;
				width: 10%;
				min-width: 80px;
				flex: 0 0 auto;

				canvas {
					vertical-align: middle;
				}
			}

			&--qr-code-link {
				color: $color-text-light;
				align-self: center;
				width: 35%;
				min-width: 200px;
				flex: 1 0 auto;

				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			&--qr-code-linked-object {
				color: $color-text-light;
				align-self: center;
				width: 30%;
				min-width: 130px;
				flex: 1 0 auto;

				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			&--logboek-heading {
				flex: 1 1 auto;
				max-width: calc(100% - 20px);
			}
		}
	}

	.list-stacked-item__column__report-title {
		display: block;
		padding: .5rem 0;
	}

}

@media screen and (min-width: 1440px) {
	.list-stacked-items--gesloten-onderhoud {
		.list-stacked-item__column--action-name,
		.list-stacked-items__index__column--action-name {
			width: 200px;
			flex-basis: 200px;
		}

		.list-stacked-item__column--action-system,
		.list-stacked-items__index__column--action-system {
			width: 200px;
			flex-basis: 200px;
		}
	}
}

@media screen and (min-width: 1600px) {
	.list-stacked-items--gesloten-onderhoud {
		.list-stacked-item__column--action-name,
		.list-stacked-items__index__column--action-name {
			width: 220px;
			flex-basis: 220px;
		}

		.list-stacked-item__column--action-system,
		.list-stacked-items__index__column--action-system {
			width: 240px;
			flex-basis: 240px;
		}
	}
}

@media screen and (min-width: 1241px) {

	.list-stacked-items__sm-only {
		display: none !important;
	}
}

/// Responsiveness for tablets
@media screen and (max-width: 1240px) {
	.card-body .list-stacked-items--gesloten-onderhoud {
		overflow: hidden
	}

	.list-stacked-items--gesloten-onderhoud .list-stacked-items__index {
		display: none;
	}

	@supports (display: grid) {
		.list-stacked-items--gesloten-onderhoud .list-stacked-item {
			width: 100%;
			padding-top: 10px;
			padding-bottom: 10px;

			display: grid;
			grid-template-columns: 1fr auto;
			grid-column-gap: 20px;

			&__column {
				// Reset column styling for grid
				min-width: auto;
				max-width: none;
				width: auto;
				padding-top: 5px;
				padding-bottom: 5px;
				color: $color-text;
				text-align: left;
				white-space: normal;

				&--action-buttons:last-child,
				&--action-short-buttons:last-child,
				&--action-long-buttons:last-child {
					grid-column: 2;
					grid-row: 1 / 10;

					justify-self: end;

					.btn-group {
						flex-direction: column;
						justify-self: stretch;

						.btn {
							border-radius: 0;
							margin: 0 !important;

							&:first-child {
								border-top-left-radius: $border-radius;
								border-top-right-radius: $border-radius;
							}

							&:last-child {
								border-bottom-left-radius: $border-radius;
								border-bottom-right-radius: $border-radius;
							}
						}
					}

					.dropdown:not(:only-child) {
						margin: 0 !important;

						.dropdown-toggle {
							display: block;
							margin: 0;
							width: 100%;
							border-radius: 0;
						}
					}
				}

				// Compensate for the labels in the ::before
				&:not(:last-child) {
					padding-left: 120px;
				}

				&--action-definitive {
					span {
						color: $color-text-light
					}
				}

				&[data-label]::before {
					position: absolute;
					top: 5px;
					left: 0;
					content: attr(data-label);
					color: $color-text;
					width: 120px;
					display: inline-block;
					text-align: left;
				}
			}

			.list-stacked-item__column__report-title {
				display: inline-block;
				padding: 0;
			}

		}
	}
}
