.list-search {
	margin: 0 -20px 45px;
	padding: 0 20px 0;
	border-bottom: 3px solid $color-bg-body;

	&:not(:first-child) {
		padding-top: 25px;
		border-top: 3px solid $color-bg-body;
		border-bottom: 3px solid $color-bg-body;
	}
}
