.nav-tabs {
	position: relative;
	padding-bottom: 0;
	margin-bottom: -1px;

	border-left: 1px solid $color-grey;
	border-top-left-radius: 4px;
	border-bottom: none;

	white-space: nowrap;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	overflow-x: auto;
	overflow-y: visible;

	z-index: 2;

	// Prevent the default elastic scrolling
	-webkit-overflow-scrolling: auto;

	.nav-item {
		display: inline-block;
		position: relative;

		// Prevent tabs from overlapping
		margin-left: -1px;
		margin-bottom: 0;
	}

	.nav-link {
		border: 1px solid $color-grey;
		border-bottom: none;
		transition: background-color .15s ease-in-out;

		&:not(.active):hover {
			border-color: $color-grey;
			background-color: darken($color-grey-light, 3%);
		}

		&.active {
			border-bottom: 1px solid white
		}
	}

	+ .tab-content {
		position: relative;

		> .card {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}
