@import "../settings";

.custom-breadcrumbs {
	padding: 5px 0;
	position: sticky;
	top: 0;
	z-index: 3;

	background: $color-bg-body;
	font-size: 14px;
	line-height: 20px;

	white-space: nowrap;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	margin: 0;

	@media #{$mq-medium-and-up} {
		margin: 0 $breadcrumbGradientOverlayWidth*-1;

		@media #{$mq-tablet-landscape} {
			margin: 0 $breadcrumbGradientOverlayWidth*-2;
		}
	}

	&:before,
	&:after {
		display: inline-block;
		vertical-align: middle;
		content: '';
		background: red;
		position: sticky;
		z-index: 2;
		height: 2em;

		width: $breadcrumbGradientOverlayWidth*2;

		@media #{$mq-tablet-only} {
			width: $breadcrumbGradientOverlayWidth;
		}
	}

	&:before {
		left: 0;
		background: linear-gradient(to right, $color-bg-body 0%, $color-bg-body 33%, rgba($color-bg-body, 0) 100%);
	}

	&:after {
		right: 0;
		background: linear-gradient(to left, $color-bg-body 0%, $color-bg-body 33%, rgba($color-bg-body, 0) 100%);
	}

	a {
		position: relative;
		color: $color-text;
		min-height: 16px;
		display: inline-block;
		vertical-align: middle;

		padding: 10px 0;
		text-decoration: none;
		margin: 0 .75em 0 0;

		&:last-child {
			font-weight: bold;
		}

		&:not(:last-child) {
			&:after {
				content: '>';
				display: inline-block;
				text-decoration: none;
				margin: 0 0 0 .75em;
			}
		}

		&:hover {
			text-decoration: underline;
			border: none
		}
	}
}
