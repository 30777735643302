/// Logboek & elementen filter
/// Filter rows and checkbox
.logboek-filter,
.elementen-filter {
	.filter-row {
		width: 100%;
		padding-bottom: 25/14*1em;
		margin-bottom: 25/14*1em;
		border-bottom: 1px solid $color-grey;

		&--checkbox-row {
			.checkbox {
				margin-bottom: 5/14*1em;
			}
		}
	}
}

/// List filter
.list-filtered {
	position: relative;
	display: block;
	min-height: 65px;
	margin-top: 20px;
	padding-bottom: 10px;

	&__no-filters {
		margin: 6px 0;
		color: $color-text-light;
	}

	&.small-filters {
		padding-top: 0;
		margin-top: 0;
		border-top: none;
	}
}

/// List filter bar
.list-filtered-bar {
	position: relative;
	border-top: 1px solid $color-grey;
	display: block;
	min-height: 65px;
	margin-top: 20px;
	padding-top: 15px;
	padding-bottom: 10px;

	&__no-filters {
		margin: 6px 0;
		color: $color-text-light;
	}

	&.small-filters {
		padding-top: 0;
		margin-top: 0;
		border-top: none;
	}
}

/// Filter tags
.tag-selected {
	border: none;
	color: $color-text;
	background-color: $color-grey-light;
	margin: 0 5px 5px 0;
	padding: 5px 14px;
	width: auto;
	cursor: pointer;
	transition: box-shadow .10s ease-in-out, background-color .10s ease-in-out;
	box-shadow: inset 0 0 0 1px darken($color-grey-light, 4%);

	&__remove {
		// Override inline styling
		color: lighten($color-text, 10%) !important;
		display: inline-block;
		margin-right: 8px;
		font-size: 20px;
		line-height: 14/20*1em;
		vertical-align: middle;

		&:hover {
			text-decoration: none;
		}
	}

	&:not(.tag-selected--not-clickable) {
		&:hover,
		&:focus,
		&:active {
			box-shadow: inset 0 0 0 1px $color-grey;
		}

		&:active:hover {
			background-color: darken($color-grey-light, 10%);
		}
	}

	&.tag-selected--not-clickable {
		cursor: default;
	}

	.select--small-tags ~ .js-select-tags-container & {
		display: block;
		width: 100%;
		flex: 1 0 100%;
		padding-top: 0;
		padding-bottom: 0;
		margin-right: 0;
		text-align: left;
	}
}

/// Select2 overwrites for multiple select tags
.select2-selection--multiple {
	background-image: url("/images/icon-search.svg");
	background-position: right 12px center;
	background-size: 20px 20px;
	background-repeat: no-repeat;

	.brigit-design & {
		background-image: url("/src/static/images/icon-search.svg");
	}
}
