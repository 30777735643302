@import "../settings";

.mobile-menu {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	padding: 10px;
	z-index: 100;
	border: none;
	background: no-repeat;
	cursor: pointer;

	@media #{$mq-medium-and-up}{
		display: none;
	}

	div {
		width: 25px;
		height: 2px;
		background-color: #000000;
		margin: 6px 0;
	}
}

.hamburger {
	cursor: pointer;

	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;

	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;

	&:hover {
		opacity: 0.7;
	}

	&.is-active {
		&:hover {
			opacity: 0.7;
		}

		.hamburger-inner,
		.hamburger-inner::before,
		.hamburger-inner::after {
			background-color: $color-grey-dark;
		}
	}
}

.hamburger-box {
	width: 25px;
	height: 30px;
	display: inline-block;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: 3px / -2;

	&,
	&::before,
	&::after {
		width: 25px;
		height: 3px;
		background-color: $color-grey-dark;
		border-radius: 4px;
		position: absolute;
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease;
	}

	&::before,
	&::after {
		content: "";
		display: block;
	}

	&::before {
		top: (4px + 3px) * -1;
	}

	&::after {
		bottom: (4px + 3px) * -1;
	}
}

.hamburger--spin {
	.hamburger-inner {
		transition-duration: 0.22s;
		transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

		&::before {
			transition: top 0.1s 0.25s ease-in,
			opacity 0.1s ease-in;
		}

		&::after {
			transition: bottom 0.1s 0.25s ease-in,
			transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
		}
	}

	&.is-active {
		.hamburger-inner {
			transform: rotate(225deg);
			transition-delay: 0.12s;
			transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

			&::before {
				top: 0;
				opacity: 0;
				transition: top 0.1s ease-out,
				opacity 0.1s 0.12s ease-out;
			}

			&::after {
				bottom: 0;
				transform: rotate(-90deg);
				transition: bottom 0.1s ease-out,
				transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
			}
		}
	}
}
