$toggle-button-border-width: 3 !default;
$toggle-button-green: #9aff9a !default;

.toggle-button {
	position: relative;
	display: inline-block;
	vertical-align: middle;

	&__input {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		z-index: 1;
	}
}

.toggle-button__toggle {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	z-index: 2;
	height: 28/14*1em;
	width: 48/14*1em;
	background: #ffffff;
	border-radius: 100/14*1em;
	border: $toggle-button-border-width/14*1em solid $color-grey;
	margin-bottom: 0;
	transition: background-color .15s ease-in-out;

	&:hover {
		cursor: pointer;
	}

	&::before, &::after {
		content: '';
		position: absolute;
	}

	&::before {
		background-color: $color-grey-dark;
		height: 28/14*1em;
		width: 28/14*1em;
		top: -$toggle-button-border-width/14*1em;
		left: -$toggle-button-border-width/14*1em;
		border-radius: 100%;
		transition: transform .15s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	}

	&::after {
		top: -12/14*1em;
		bottom: -12/14*1em;
		left: -12/14*1em;
		right: -12/14*1em;
	}
}

// Active state
input:checked ~ .toggle-button__toggle,
.toggle-button__toggle.active {
	background-color: $toggle-button-green;
	border-color: $color-grey;

	&::before {
		transform: translate3d(calc(100% - #{(8 / 14 * 1em)}), 0, 0);
	}
}

// Disabled state
input[disabled] ~ .toggle-button__toggle,
.toggle-button__toggle.disabled {
	cursor: not-allowed;
}

.toggle-button__text-label {
	display: inline-block;
	vertical-align: middle;
	margin: 0 0 0 8/14*1em;

	.toggle-button__text-label__checked {
		color: $color-text;
		display: none;

		input:checked ~ & {
			display: block;
		}
	}

	.toggle-button__text-label__unchecked {
		color: $color-text;
		display: block;

		input:checked ~ & {
			display: none;
		}
	}

}

.toggle-button__warning {
	input:checked ~ &,
	.active ~ & {
		display: none;
	}
}
