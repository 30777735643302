/// Base card styling
.card {
	margin: 0 0 30px;
	position: relative;
	border: 1px solid $color-grey;
}

/// Base card body styling
.card-body {
	padding: 20px;
}

/// Base card title/header styling
.card-title,
.card-header {
	margin-top: 0 !important;
}

/// Base card header/footer styling
.card-header,
.card-footer {
	background-color: $light;
	border-bottom-color: darken($light, 5%);
}

/// Base card footer styling
.card-footer {
	border-top-color: darken($light, 5%);
}

/// Base card heading styling
.card-heading {

	&-buttons {
		margin-top: -5px;
		float: right;

		.btn {
			margin-left: 5px;
		}
	}

	&-qr-export {
		margin: 0 0 25px;
		text-align: right;
	}

}

/// Card tabs
.nav-tabs--card {
	color: #646464;
	font-size: 16px;
	line-height: 22/16*1em;
	font-weight: 400;

	a {
		color: inherit;
	}

	.nav-item a {
		padding: .5em 10/16*1em;
	}
}

/// Card tabs search amount
.nav-tabs--amount {
	font-size: 14px;
}
