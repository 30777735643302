.btn.btn-primary {
	background-color: $color-grey-dark;
	border: none;
	transition: background-color .15s ease-in-out;

	&:hover {
		background-color: lighten($color-grey-dark, 5%);
		cursor: pointer;
	}
}

button[disabled] {
	cursor: not-allowed !important;
}

.btn.btn-link {
	text-decoration: none;
	color: $color-text-light;

	&:hover, &:focus {
		text-decoration: underline;
	}
}

.btn.btn.btn-outline-secondary:not(:hover:focus):not(.active) {
	border-color: $color-grey;
	color: $color-text-light;

	&:hover:not(:disabled) {
		color: #ffffff;
	}
}

.btn--input-height {
	padding: .469rem .75rem;
}

.btn--min-width {
	min-width: 130/14*1em;
	margin-bottom: 8px;

	&-small {
		min-width: 90/14*1em;
	}

	&.btn-link {
		min-width: 120/14*1em;
	}

	@media only screen and (min-width: 1200px) {
		margin-bottom: 0;
	}
}

table .btn-sm {
	height: 28px;
}

.document-selected {
	border: none;
	color: $color-text;
	background-color: $color-grey-light;
	margin: 0 5px 0 0;
	padding: 5px 14px;
	width: auto;
	cursor: pointer;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	max-width: 200px !important;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	&:hover {
		text-decoration: underline;
	}

	&--delete {
		border: none;
		margin: 0 5px 0 0;
		padding: 5px 14px;
		color: $color-text;
		background-color: $color-grey-light;
		width: auto;
		border-left: 1px solid darken($color-grey-light, 8%);
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;

		&:hover {
			color: #dc3545;
		}
	}
}

// Button icons
.btn {
	max-width: 100%;

	.btn-icon {
		max-width: 20/14*1em;
		max-height: 20/14*1em;
		display: inline-block;
		vertical-align: middle;
		margin-right: 8/14*1em;
		margin-top: -3/14*1em;

		&--pdf {
			margin-top: -5px;
		}

		// Adjust spacing for icons on the right
		&--right {
			margin-left: 3/14*1em;
			margin-right: 0;
			text-align: right;
		}

	}

	&.btn-outline-green {
		color: $green;
		border-color: $green;

		&.active,
		&:hover {
			background-color: $green !important;
			border-color: $green !important;
			color: $white;
		}
	}

	&.btn-outline-red {
		color: $red;
		border-color: $red;

		&.active,
		&:hover {
			background-color: $red !important;
			border-color: $red !important;
			color: $white;
		}
	}

	&.btn-outline-blue {
		color: $blue;
		border-color: $blue;

		&.active,
		&:hover {
			background-color: $blue !important;
			border-color: $blue !important;
			color: $white;
		}
	}

	svg {
		display: inline-block;
		vertical-align: middle;
		fill: currentColor;
	}

	.icon-pdf {
		vertical-align: text-top;
	}
}

.btn--clear-value {
	min-width: 0 !important;
	max-width: 31px !important;
	width: 31px !important;
}

.btn-outline-secondary.focus:not(:focus-visible),
.btn-outline-secondary:focus:not(:focus-visible),
button:focus:not(:focus-visible) {
	outline: none;
	box-shadow: none;
}
